import dayjs from "dayjs"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { ImageUnavailable } from "../../../assets/svgs/imageUnavailable.svg"
import { useProductHistory } from "../../../hooks/productHistory.hook"
import { RunTimeStateSelector } from "../../../selectors/RunTimeStateSelector"
import ArrowModalActions from "../../common/arrowModalActions.component"
import InnerModalHeader from "../../common/innerModalHeader.component"
import { PrimaryText } from "../../elements/primaryText.element"
import Slider from "../../elements/slider.component"
import { ChartsWrapper } from "./chartsWrapper.component"

export const CarousalModal = (data: IDialogOptionsProps) => {
    const { active, history, setActive, total } = useProductHistory(data?.data as IProductData, "carousel_images")
    const { modal } = useSelector(RunTimeStateSelector)

    const { t: tCommon } = useTranslation("tooltip", {
        keyPrefix: "commonLabels",
    })

    const carouselIndex = modal?.index
    const pastImages = history?.past?.value || []
    const presentImages = history?.present?.value || []

    const reorderImages = (images: any, index: number | undefined) => {
        if (!images || images?.length === 0) return []
        const reordered = [...images]
        const matchedImageIndex = images.findIndex((_: any, i: number) => i === index)
        if (matchedImageIndex !== -1) {
            const [firstImage] = reordered.splice(matchedImageIndex, 1)
            reordered.unshift(firstImage)
        }
        return reordered
    }

    const reorderedPastImages = reorderImages(pastImages, carouselIndex)
    const reorderedPresentImages = reorderImages(presentImages, carouselIndex)

    const pastPresentData = useMemo(() => {
        const past = []
        const present = []
        const maxLength = Math.max(reorderedPastImages?.length, reorderedPresentImages?.length)

        for (let i = 0; i < maxLength; i++) {
            const pastData = reorderedPastImages[i] !== undefined ? reorderedPastImages[i] : ""
            const presentData = reorderedPresentImages[i] !== undefined ? reorderedPresentImages[i] : ""

            if (pastData !== presentData || pastData === "" || presentData === "") {
                past.push(pastData)
                present.push(presentData)
            }
        }

        return { past, present }
    }, [reorderedPastImages, reorderedPresentImages])

    return (
        <>
            <ChartsWrapper
                className={`p-[48px] pb-[42px] absolute top-[50%] left-[50%] -translate-y-2/4 -translate-x-2/4 bg-white rounded-[24px] m-auto ${
                    history?.present?.value.length && history.past?.value.length ? "w-[900px]" : "w-[492px]"
                } shadow-modal-shadow border-[1px] border-solid border-[#E7EBEF] transition-all`}
            >
                <InnerModalHeader color={modal?.color} type="carousel" />
                <PrimaryText className="text-[#344054] ml-[6px] text-[24px] font-[500] leading-[32px] mb-[4px]">
                    Carousel Images
                </PrimaryText>
                <div className={` ${total > 1 ? "ml-[8px] flex items-center gap-[10px]" : "ml-[6px]"}`}>
                    <ArrowModalActions total={total} active={active} setActive={setActive} />

                    <PrimaryText className="text-[12px] uppercase leading-[12px] text-[#667085] font-[400]">
                        {tCommon("timestamp", {
                            timestamp: dayjs(history?.present?.timestamp).format("MMM-DD-YYYY @ HH:mm [GMT]"),
                        })}
                    </PrimaryText>
                </div>
                <div className="mt-[40px]">
                    <div className="flex gap-[12px]">
                        <Slider isLargeIcons={true} color={modal?.color} type="Image" className="!m-0">
                            {Array.from(
                                {
                                    length: Math.max(pastPresentData.past?.length, pastPresentData.present?.length),
                                },
                                (_, index) => {
                                    const pastData = pastPresentData.past[index]
                                    const presentData = pastPresentData.present[index]

                                    return (
                                        <div
                                            className={`embla__slide flex gap-[12px] ${
                                                (pastPresentData.past.length === 1 &&
                                                    pastPresentData.present.length === 1) ||
                                                (pastPresentData.past.length >= 1 &&
                                                    pastPresentData.present.length >= 1)
                                                    ? ""
                                                    : "mr-[12px]"
                                            }`}
                                            key={index}
                                        >
                                            {data?.data?.isFirst === true ? (
                                                presentData && (
                                                    <div className="bg-[#F2F4F7] p-4 rounded-[16px]">
                                                        <div className="w-[32px] py-[5.5px] px-[6px] text-white bg-[#47CD89] rounded-[6px] mb-[16px] text-[10px] leading-[9px] font-[500] flex items-center justify-center">
                                                            {tCommon("now")}
                                                        </div>
                                                        <div className="w-[364px] h-[364px] overflow-hidden border border-gray-200 rounded-[8px]">
                                                            <img
                                                                className="w-full h-full object-contain bg-gray-900"
                                                                src={presentData}
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div className="mt-[16px] w-[20px] h-[20px] bg-gray-400 rounded-[6px] flex-col justify-center items-center flex">
                                                            <div className="pt-[0px] text-center text-white text-[12px] font-[500] font-['F37 Bolton'] leading-[6px]">
                                                                {index + 1}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            ) : (
                                                <>
                                                    {(pastData || pastData === "") && (
                                                        <div className="bg-[#F2F4F7] rounded-[16px] p-4">
                                                            <div className="w-[32px] py-[5.5px] px-[6px] bg-[#F04438] text-white rounded-[6px] mb-[16px] text-[10px] leading-[9px] font-[500] flex items-center justify-center">
                                                                {tCommon("was")}
                                                            </div>
                                                            <div className="w-[364px] h-[364px] overflow-hidden border border-gray-200 rounded-[8px]">
                                                                {pastData === "" ? (
                                                                    <div className="object-contain chrome:w-full safari:w-full h-full rounded-[4px] flex items-center justify-center border border-gray-200 select-none cursor-pointer bg-gray-900">
                                                                        <ImageUnavailable width={156} height={156} />
                                                                    </div>
                                                                ) : (
                                                                    <img
                                                                        className="w-full h-full object-contain bg-gray-900"
                                                                        src={pastData}
                                                                        alt=""
                                                                    />
                                                                )}
                                                            </div>
                                                            <div className="mt-[16px] w-[20px] h-[20px] bg-gray-400 rounded-[6px] flex-col justify-center items-center flex">
                                                                <div className="pt-[0px] text-center text-white text-[12px] font-[500] leading-[6px]">
                                                                    {index + 1}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}

                                                    {(presentData || presentData === "") && (
                                                        <div className="bg-[#F2F4F7] p-4 rounded-[16px]">
                                                            <div className="w-[32px] py-[5.5px] px-[6px] text-white bg-[#47CD89] rounded-[6px] mb-[16px] text-[10px] leading-[9px] font-[500] flex items-center justify-center">
                                                                {tCommon("now")}
                                                            </div>
                                                            <div className="w-[364px] h-[364px] overflow-hidden border border-gray-200 rounded-[8px]">
                                                                {presentData === "" ? (
                                                                    <div className="object-contain chrome:w-full safari:w-full h-full rounded-[4px] flex items-center justify-center border border-gray-200 select-none cursor-pointer bg-gray-900">
                                                                        <ImageUnavailable width={156} height={156} />
                                                                    </div>
                                                                ) : (
                                                                    <img
                                                                        className="w-full h-full object-contain bg-gray-900"
                                                                        src={presentData}
                                                                        alt=""
                                                                    />
                                                                )}
                                                            </div>
                                                            <div className="mt-[16px] w-[20px] h-[20px] bg-gray-400 rounded-[6px] flex-col justify-center items-center flex">
                                                                <div className="pt-[0px] text-center text-white text-[12px] font-[500] font-['F37 Bolton'] leading-[6px]">
                                                                    {index + 1}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    )
                                }
                            )}
                        </Slider>
                    </div>
                </div>
            </ChartsWrapper>
        </>
    )
}
