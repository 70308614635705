import { useMemo } from "react"
import { useSelector } from "react-redux"
import { RuntimeHelper } from "../../../../helpers/runtime.helper"
import { ProductStateSelector } from "../../../../selectors/product.selector"
import { RunTimeStateSelector } from "../../../../selectors/RunTimeStateSelector"
import SemiAutomatedTrackPackOutlet from "./semiAutomatedFlowOutlet.component"
import { SemiAutomatedWrapperModal } from "./semiAutomatedWrapperModal.component"

const SemiAutomatedAddTrackPackMain = () => {
    const { semiAutomatedAddTrackPack } = useSelector(RunTimeStateSelector)
    const { competitorsData } = useSelector(ProductStateSelector)

    const { loading } = useSelector(RunTimeStateSelector)
    const isLoading: boolean = useMemo(() => RuntimeHelper.competitorsDataListLoading(), [loading])

    const isStepThree = semiAutomatedAddTrackPack?.stepNumber === "three" ? true : false

    return (
        <SemiAutomatedWrapperModal
            className={`${
                isStepThree ? "p-[24px] pb-[0px]" : "p-[48px] pb-[24px]"
            } absolute top-[50%] left-[50%] -translate-y-2/4 -translate-x-2/4 bg-white rounded-[24px] ${
                isStepThree && !isLoading && competitorsData?.bestsellers?.length !== 0
                    ? "max-w-[1096px] min-w-[1096px]"
                    : "w-[600px]"
            }  shadow-modal-shadow border-[1px] border-solid border-[#E7EBEF] transition-all`}
        >
            <SemiAutomatedTrackPackOutlet />
        </SemiAutomatedWrapperModal>
    )
}

export default SemiAutomatedAddTrackPackMain
