export const CurrentIndecator = () => {
    return (
        <>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_14469_416642)">
                    <rect width="24" height="24" rx="12" fill="#ECFDFF" />
                    <rect x="0.75" y="0.75" width="22.5" height="22.5" rx="11.25" fill="#ECFDFF" />
                    <rect x="0.75" y="0.75" width="22.5" height="22.5" rx="11.25" stroke="#088AB2" stroke-width="1.5" />
                    <circle cx="12" cy="12" r="4" fill="#088AB2" />
                </g>
                <defs>
                    <clipPath id="clip0_14469_416642">
                        <rect width="24" height="24" rx="12" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </>
    )
}
