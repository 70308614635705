export const ImageUnavailable = (props: any) => {
    return (
        <svg
            width={props?.width ?? 48}
            height={props?.height ?? 49}
            viewBox="0 0 48 49"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M32.2703 6.5L42 16.2297M42 6.5L32.2703 16.2297M24.4865 7.47297H15.3405C12.071 7.47297 10.4363 7.47297 9.18751 8.10926C8.08906 8.66895 7.19598 9.56203 6.63629 10.6605C6 11.9093 6 13.544 6 16.8135V33.1595C6 36.429 6 38.0637 6.63629 39.3125C7.19598 40.4109 8.08906 41.304 9.18751 41.8637C10.4363 42.5 12.071 42.5 15.3405 42.5H33.2432C35.0529 42.5 35.9578 42.5 36.7001 42.3011C38.7147 41.7613 40.2883 40.1877 40.8281 38.1731C41.027 37.4307 41.027 36.5259 41.027 34.7162M20.5946 18.1757C20.5946 20.3251 18.8521 22.0676 16.7027 22.0676C14.5533 22.0676 12.8108 20.3251 12.8108 18.1757C12.8108 16.0262 14.5533 14.2838 16.7027 14.2838C18.8521 14.2838 20.5946 16.0262 20.5946 18.1757ZM29.332 24.8272L12.8714 39.7913C11.9456 40.633 11.4826 41.0539 11.4417 41.4184C11.4062 41.7344 11.5274 42.0478 11.7662 42.2578C12.0417 42.5 12.6674 42.5 13.9186 42.5H32.1846C34.9851 42.5 36.3854 42.5 37.4852 42.0295C38.8659 41.4389 39.9659 40.3389 40.5565 38.9582C41.027 37.8584 41.027 36.4581 41.027 33.6576C41.027 32.7153 41.027 32.2442 40.924 31.8054C40.7946 31.254 40.5463 30.7375 40.1966 30.2919C39.9183 29.9374 39.5504 29.643 38.8146 29.0544L33.3713 24.6998C32.6349 24.1106 32.2667 23.8161 31.8612 23.7121C31.5038 23.6205 31.1277 23.6323 30.7768 23.7463C30.3787 23.8756 30.0298 24.1928 29.332 24.8272Z"
                stroke="#475467"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    )
}
