import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ProductsActionCreator } from "../../../../actions/products.action"
import { RuntimeActionCreators } from "../../../../actions/runTime.action"
import { NewAddAsinsIcon } from "../../../../assets/svgs/newAddAsins.svg"
import { NewAsinIcon } from "../../../../assets/svgs/newAsinIcon.svg"
import { ThumbIcon } from "../../../../assets/svgs/thumbIcon.svg"
import { TrackPackError } from "../../../../assets/svgs/trackPackError.svg"
import { RunTimeStateSelector } from "../../../../selectors/RunTimeStateSelector"
import AddTrackPackStepFourView from "../addTrackPackModalSteps/addTrackPackStepFour.component"
import AddTrackPackStepOneView from "../addTrackPackModalSteps/addTrackPackStepOne.component"
import AddTrackPackStepTwoView from "../addTrackPackModalSteps/addTrackPackStepTwo.component"
import AdditionalTrackpackStepFiveView from "./additionalTrackpackstepFiveView.component"

const AdditionalTrackPackOutlet = () => {
    const { newAsinAdditional } = useSelector(RunTimeStateSelector)
    const dispatch = useDispatch()
    const [hasAsinError, setHasAsinError] = useState(false)
    const [isAsinSuccess, setIsAsinSuccess] = useState(false)
    const [asinsCount, setAsinsCount] = useState(0)

    const handleCloseDialog = () => {
        dispatch(RuntimeActionCreators.closeAsinAddtionalModal())
        dispatch(
            ProductsActionCreator.selectedTrackpackPayload({
                name: "",
                amazon_tld: "",
                competitor_asins: [],
                own_asins: [],
                total_asins: [],
                selected_Asins: {},
            })
        )
        setHasAsinError(false)
        setIsAsinSuccess(false)
    }

    const handleErrorModal = () => {
        setHasAsinError(true)
        dispatch(
            RuntimeActionCreators.openAsinAddtionalModal({
                open: true,
                stepNumber: "three",
            })
        )
    }
    const handleSuccessModal = (count: number) => {
        setIsAsinSuccess(true)
        setAsinsCount(count)
        dispatch(
            RuntimeActionCreators.openAsinAddtionalModal({
                open: true,
                stepNumber: "four",
            })
        )
    }

    const getModalBody = () => {
        if (isAsinSuccess && newAsinAdditional?.stepNumber === "four") {
            return (
                <AdditionalTrackpackStepFiveView
                    icon={<ThumbIcon />}
                    handleCloseDialog={handleCloseDialog}
                    handlePrev={() =>
                        dispatch(
                            RuntimeActionCreators.openAsinAddtionalModal({
                                open: true,
                                stepNumber: "one",
                            })
                        )
                    }
                    asinHeaderText="Great work"
                    buttonText="Done"
                    asinsCount={asinsCount}
                />
            )
        }
        if (hasAsinError && newAsinAdditional?.stepNumber === "three") {
            return (
                <AddTrackPackStepFourView
                    icon={<TrackPackError />}
                    handleCloseDialog={handleCloseDialog}
                    handlePrev={() =>
                        dispatch(
                            RuntimeActionCreators.openAsinAddtionalModal({
                                open: true,
                                stepNumber: "one",
                            })
                        )
                    }
                    asinHeaderText="Error adding new ASINs"
                    asinHeaderDescription="I couldn't add new ASINs to your Trackpack at this time due to a server communication issue. Please try again later."
                    buttonText="Done"
                />
            )
        }
        switch (newAsinAdditional?.stepNumber) {
            case "one":
                return (
                    <AddTrackPackStepOneView
                        icon={<NewAddAsinsIcon />}
                        handleCloseDialog={handleCloseDialog}
                        asinHeaderText="Add ASINs to your Trackpack"
                        asinHeaderDescription=""
                        buttonText="Next"
                        isAddtionalSteps={true}
                        handleNext={() =>
                            dispatch(
                                RuntimeActionCreators.openAsinAddtionalModal({
                                    open: true,
                                    stepNumber: "two",
                                })
                            )
                        }
                        isError={handleErrorModal}
                        isSuccess={(count) => handleSuccessModal(count)}
                    />
                )
            case "two":
                return (
                    <AddTrackPackStepTwoView
                        icon={<NewAsinIcon />}
                        handleCloseDialog={handleCloseDialog}
                        asinHeaderText="Your ASINs"
                        asinHeaderDescription="Tick any ASINs that belong to you."
                        buttonText="Done"
                        isAddtionalSteps={true}
                        handlePrev={() =>
                            dispatch(
                                RuntimeActionCreators.openAsinAddtionalModal({
                                    open: true,
                                    stepNumber: "one",
                                })
                            )
                        }
                        isError={handleErrorModal}
                        isSuccess={(count) => handleSuccessModal(count)}
                    />
                )

            default:
                return <></>
        }
    }
    return <>{newAsinAdditional?.open ? <>{getModalBody()}</> : null}</>
}

export default AdditionalTrackPackOutlet