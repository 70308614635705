import AustraliaImg from "../assets/images/aus.jpg"
import BelgiumImg from "../assets/images/belgium.png"
import BrazilImg from "../assets/images/brazil.jpg"
import CanadaImg from "../assets/images/canada.jpg"
import ChineImg from "../assets/images/china.jpg"
import EgyptImg from "../assets/images/egypt.jpg"
import FranceImg from "../assets/images/france.jpg"
import GermanyImg from "../assets/images/germany.png"
import trackpackImg from "../assets/images/image11.png"
import IndiaImg from "../assets/images/india.png"
import ItalyImg from "../assets/images/italy.png"
import JapanImg from "../assets/images/japan.png"
import MexicoImg from "../assets/images/maxico.jpg"
import NetherlandImg from "../assets/images/netherland.jpg"
import PolandImg from "../assets/images/poleand.png"
import SaudiaImg from "../assets/images/saudia.png"
import SpainImg from "../assets/images/spanin.jpg"
import SwedenImg from "../assets/images/sweden.jpg"
import TurkeyImg from "../assets/images/turkey.jpg"
import UaeImg from "../assets/images/uae.jpg"
import UkImg from "../assets/images/uk.png"

import SindaporeImg from "../assets/images/singapore.jpg"
import AsinChangeIcon from "../assets/svgs/filters/AsinChangeFilterIcon.svg"
import BulletIcon from "../assets/svgs/filters/BulletFilterIcon.svg"
import CarouselIcon from "../assets/svgs/filters/CarouselFilterIcon.svg"
import DescriptionIcon from "../assets/svgs/filters/DescriptionFilterIcon.svg"
import MainImageIcon from "../assets/svgs/filters/MainImageFilterIcon.svg"
import PriceIcon from "../assets/svgs/filters/PriceFilterIcon.svg"
import StockIcon from "../assets/svgs/filters/StockFilterIcon.svg"
import TitleIcon from "../assets/svgs/filters/TitleFilterIcon.svg"
import SellerIcon from "../assets/svgs/filters/TopSellerFilterIcon.svg"
import VideoIcon from "../assets/svgs/filters/VideoFilterIcon.svg"
import UsaIcon from "../assets/svgs/usaIcon.png"

export const DashboardBoardConfig = {
    graphConfig: {
        productDifferentiateItems: [
            { value: "actual_asin", label: "ASIN", component: AsinChangeIcon, width: "32px" },
            { value: "seller_info", label: "Seller Info", component: SellerIcon, width: "54px" },
            { value: "stock", label: "Stock", component: StockIcon, width: "35px" },
            { value: "price", label: "Price", component: PriceIcon, width: "32px" },
            { value: "title", label: "Title", component: TitleIcon, width: "30px" },
            { value: "bullets", label: "Bullets", component: BulletIcon, width: "40px" },
            { value: "description", label: "Description", component: DescriptionIcon, width: "58px" },
            { value: "main_image", label: "Main Image", component: MainImageIcon, width: "59px" },
            { value: "carousel_images", label: "Carousel", component: CarouselIcon, width: "48px" },
            { value: "videos", label: "Video", component: VideoIcon, width: "34px" },
        ],
        productResults: [
            { value: "bsr_large", label: "BSR L Cat", LogViewLabel: "BSR Large Category" },
            { value: "bsr_small", label: "BSR S Cat", LogViewLabel: "BSR Small Category" },
            { value: "ratings_count", label: "Ratings Count", LogViewLabel: "Ratings Count" },
            { value: "rating", label: "Review Score", LogViewLabel: "Review Score" },
        ],
        productRange: [
            { value: "daily", label: "Daily" },
            { value: "weekly", label: "Weekly" },
            { value: "monthly", label: "Monthly" },
        ],
        productRangeUpdated: [
            { value: 7, label: "1<span className='text-[9px]'>W</span>", labelName: "1 Week", width: "42px" },
            { value: 14, label: "2<span className='text-[9px]'>W</span>", labelName: "2 Week", width: "46px" },
            { value: 30, label: "1<span className='text-[9px]'>M</span>", labelName: "1 Month", width: "48px" },
            { value: 180, label: "6<span className='text-[9px]'>M</span>", labelName: "6 Months", width: "50px" },
            { value: 0, label: "A<span className='text-[9px]'>LL</span>", labelName: "All", width: "28px" },
        ],
    },
}

export const img_url = "https://amaizing.sfo3.digitaloceanspaces.com/public/images/"

export const TrackPacks = [
    {
        title: "Men’s Suit Jackets",
        userASINs: 1,
        competitorASINs: 7,
        imageSources: [
            trackpackImg,
            trackpackImg,
            trackpackImg,
            trackpackImg,
            trackpackImg,
            trackpackImg,
            trackpackImg,
        ],
        actionCount: 10,
        trackpackChildrenList: [
            {
                ASINs: {
                    asinNo: "B08N5WRWNV",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "Ah",
                "Date Added": "2023-06-01",
                time_deletion: "120 day",
            },
            {
                ASINs: {
                    asinNo: "B08N5RVASV",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "Ah",
                "Date Added": "2023-06-01",
                time_deletion: "120 day",
            },
            {
                ASINs: {
                    asinNo: "B08N5WRWNZ",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "JH",
                "Date Added": "2023-06-02",
                time_deletion: "90 day",
            },
            {
                ASINs: {
                    asinNo: "B08N5WRWNY",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "AL",
                "Date Added": "2023-06-03",
                time_deletion: "80 day",
            },
            {
                ASINs: {
                    asinNo: "B08N5WDWNW",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "BO",
                "Date Added": "2023-06-04",
                time_deletion: "110 day",
            },
            {
                ASINs: {
                    asinNo: "B08N5XRWNW",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "JD",
                "Date Added": "2023-06-01",
                time_deletion: "40 day",
            },
            {
                ASINs: {
                    asinNo: "B08N5CRWNW",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "JM",
                "Date Added": "2023-06-02",
                time_deletion: "60 day",
            },
            {
                ASINs: {
                    asinNo: "B08N5WFWNW",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "JM",
                "Date Added": "2023-06-02",
                time_deletion: "190 day",
            },
            {
                ASINs: {
                    asinNo: "B08N5WRWxx",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "JM",
                "Date Added": "2023-06-02",
                time_deletion: "20 day",
            },
            {
                ASINs: {
                    asinNo: "B08N5WRWxx",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "JM",
                "Date Added": "2023-06-02",
                time_deletion: "20 day",
            },
        ],
    },
    {
        title: "Men’s T-Shirts",
        userASINs: 1,
        competitorASINs: 4,
        imageSources: [trackpackImg, trackpackImg, trackpackImg, trackpackImg, trackpackImg, trackpackImg],
        actionCount: 5,
        trackpackChildrenList: [
            {
                ASINs: {
                    asinNo: "B08N5WDWNW",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "BO",
                "Date Added": "2023-06-04",
                time_deletion: "110 day",
            },
            {
                ASINs: {
                    asinNo: "B08N5XRWNW",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "JD",
                "Date Added": "2023-06-01",
                time_deletion: "40 day",
            },
        ],
    },
    {
        title: "Women’s Leggings",
        userASINs: 2,
        competitorASINs: 8,
        imageSources: [
            trackpackImg,
            trackpackImg,
            trackpackImg,
            trackpackImg,
            trackpackImg,
            trackpackImg,
            trackpackImg,
            trackpackImg,
        ],
        actionCount: 10,
        trackpackChildrenList: [
            {
                ASINs: {
                    asinNo: "B08N5WRWNV",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "Ah",
                "Date Added": "2023-06-01",
                time_deletion: "120 day",
            },
            {
                ASINs: {
                    asinNo: "B08N5WRWNZ",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "JH",
                "Date Added": "2023-06-02",
                time_deletion: "90 day",
            },
            {
                ASINs: {
                    asinNo: "B08N5WRWNY",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "AL",
                "Date Added": "2023-06-03",
                time_deletion: "80 day",
            },
            {
                ASINs: {
                    asinNo: "B08N5WDWNW",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "BO",
                "Date Added": "2023-06-04",
                time_deletion: "110 day",
            },
        ],
    },
    {
        title: "Women’s Leggings",
        userASINs: 2,
        competitorASINs: 8,
        imageSources: [trackpackImg, trackpackImg, trackpackImg, trackpackImg, trackpackImg, trackpackImg],
        actionCount: 10,
        trackpackChildrenList: [
            {
                ASINs: {
                    asinNo: "B08N5WRWNY",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "AL",
                "Date Added": "2023-06-03",
                time_deletion: "80 day",
            },
            {
                ASINs: {
                    asinNo: "B08N5WDWNW",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "BO",
                "Date Added": "2023-06-04",
                time_deletion: "110 day",
            },
            {
                ASINs: {
                    asinNo: "B08N5XRWNW",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "JD",
                "Date Added": "2023-06-01",
                time_deletion: "40 day",
            },

            {
                ASINs: {
                    asinNo: "B08N5WFWNW",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "JM",
                "Date Added": "2023-06-02",
                time_deletion: "190 day",
            },
            {
                ASINs: {
                    asinNo: "B08N5WRWxx",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "JM",
                "Date Added": "2023-06-02",
                time_deletion: "20 day",
            },
        ],
    },
    {
        title: "Women’s Leggings",
        userASINs: 2,
        competitorASINs: 8,
        imageSources: [
            trackpackImg,
            trackpackImg,
            trackpackImg,
            trackpackImg,
            trackpackImg,
            trackpackImg,
            trackpackImg,
            trackpackImg,
            trackpackImg,
            trackpackImg,
            trackpackImg,
            trackpackImg,
        ],
        actionCount: 10,
        trackpackChildrenList: [
            {
                ASINs: {
                    asinNo: "B08N5WRWNV",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "Ah",
                "Date Added": "2023-06-01",
                time_deletion: "120 day",
            },
            {
                ASINs: {
                    asinNo: "B08N5WRWNZ",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "JH",
                "Date Added": "2023-06-02",
                time_deletion: "90 day",
            },
            {
                ASINs: {
                    asinNo: "B08N5WRWNY",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "AL",
                "Date Added": "2023-06-03",
                time_deletion: "80 day",
            },
            {
                ASINs: {
                    asinNo: "B08N5WDWNW",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "BO",
                "Date Added": "2023-06-04",
                time_deletion: "110 day",
            },
        ],
    },
    {
        title: "Women’s Leggings",
        userASINs: 2,
        competitorASINs: 8,
        imageSources: [trackpackImg, trackpackImg, trackpackImg, trackpackImg, trackpackImg, trackpackImg],
        actionCount: 10,
        trackpackChildrenList: [
            {
                ASINs: {
                    asinNo: "B08N5WRWNV",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "Ah",
                "Date Added": "2023-06-01",
                time_deletion: "120 day",
            },
            {
                ASINs: {
                    asinNo: "B08N5WRWNZ",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "JH",
                "Date Added": "2023-06-02",
                time_deletion: "90 day",
            },
            {
                ASINs: {
                    asinNo: "B08N5WRWNY",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "AL",
                "Date Added": "2023-06-03",
                time_deletion: "80 day",
            },
        ],
    },
    {
        title: "Women’s Leggings",
        userASINs: 2,
        competitorASINs: 8,
        imageSources: [
            trackpackImg,
            trackpackImg,
            trackpackImg,
            trackpackImg,
            trackpackImg,
            trackpackImg,
            trackpackImg,
        ],

        actionCount: 10,
        trackpackChildrenList: [
            {
                ASINs: {
                    asinNo: "B08N5WRWNV",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "Ah",
                "Date Added": "2023-06-01",
                time_deletion: "120 day",
            },
            {
                ASINs: {
                    asinNo: "B08N5WRWNZ",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "JH",
                "Date Added": "2023-06-02",
                time_deletion: "90 day",
            },
            {
                ASINs: {
                    asinNo: "B08N5WRWNY",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "AL",
                "Date Added": "2023-06-03",
                time_deletion: "80 day",
            },
            {
                ASINs: {
                    asinNo: "B08N5WDWNW",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "BO",
                "Date Added": "2023-06-04",
                time_deletion: "110 day",
            },
            {
                ASINs: {
                    asinNo: "B08N5XRWNW",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "JD",
                "Date Added": "2023-06-01",
                time_deletion: "40 day",
            },
            {
                ASINs: {
                    asinNo: "B08N5CRWNW",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "JM",
                "Date Added": "2023-06-02",
                time_deletion: "60 day",
            },
            {
                ASINs: {
                    asinNo: "B08N5WFWNW",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "JM",
                "Date Added": "2023-06-02",
                time_deletion: "190 day",
            },
            {
                ASINs: {
                    asinNo: "B08N5WRWxx",
                    asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
                    imgUrl: trackpackImg,
                },
                Status: ["Tracking", "Archived"],
                Owner: "JM",
                "Date Added": "2023-06-02",
                time_deletion: "20 day",
            },
        ],
    },
]

export const rowData = [
    {
        ASINs: {
            asinNo: "B08N5WRWNV",
            asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
            imgUrl: trackpackImg,
        },
        Status: ["Tracking", "Archived"],
        Owner: "Ah",
        "Date Added": "2023-06-01",
        time_deletion: "120 day",
    },
    {
        ASINs: {
            asinNo: "B08N5WRWNZ",
            asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
            imgUrl: trackpackImg,
        },
        Status: ["Tracking", "Archived"],
        Owner: "JH",
        "Date Added": "2023-06-02",
        time_deletion: "90 day",
    },
    {
        ASINs: {
            asinNo: "B08N5WRWNY",
            asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
            imgUrl: trackpackImg,
        },
        Status: ["Tracking", "Archived"],
        Owner: "AL",
        "Date Added": "2023-06-03",
        time_deletion: "80 day",
    },
    {
        ASINs: {
            asinNo: "B08N5WDWNW",
            asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
            imgUrl: trackpackImg,
        },
        Status: ["Tracking", "Archived"],
        Owner: "BO",
        "Date Added": "2023-06-04",
        time_deletion: "110 day",
    },
    {
        ASINs: {
            asinNo: "B08N5XRWNW",
            asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
            imgUrl: trackpackImg,
        },
        Status: ["Tracking", "Archived"],
        Owner: "JD",
        "Date Added": "2023-06-01",
        time_deletion: "40 day",
    },
    {
        ASINs: {
            asinNo: "B08N5CRWNW",
            asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
            imgUrl: trackpackImg,
        },
        Status: ["Tracking", "Archived"],
        Owner: "JM",
        "Date Added": "2023-06-02",
        time_deletion: "60 day",
    },
    {
        ASINs: {
            asinNo: "B08N5WFWNW",
            asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
            imgUrl: trackpackImg,
        },
        Status: ["Tracking", "Archived"],
        Owner: "JM",
        "Date Added": "2023-06-02",
        time_deletion: "190 day",
    },
    {
        ASINs: {
            asinNo: "B08N5WRWxx",
            asinText: "PJ PAUL JONES Men’s Casual Knit Blazer Suit Jackets ...",
            imgUrl: trackpackImg,
        },
        Status: ["Tracking", "Archived"],
        Owner: "JM",
        "Date Added": "2023-06-02",
        time_deletion: "20 day",
    },
]

export const countriesList = [
    {
        value: "USA",
        label: "USA",
        flagUrl: UsaIcon,
    },
    {
        value: "australia",
        label: "Australia",
        flagUrl: AustraliaImg,
    },
    {
        value: "belgium",
        label: "Belgium",
        flagUrl: BelgiumImg,
    },
    {
        value: "brazil",
        label: "Brazil",
        flagUrl: BrazilImg,
    },
    {
        value: "canada",
        label: "Canada",
        flagUrl: CanadaImg,
    },

    {
        value: "china",
        label: "China",
        flagUrl: ChineImg,
    },
    {
        value: "egypt",
        label: "Egypt",
        flagUrl: EgyptImg,
    },
    {
        value: "france",
        label: "France",
        flagUrl: FranceImg,
    },
    {
        value: "germany",
        label: "Germany",
        flagUrl: GermanyImg,
    },
    {
        value: "india",
        label: "India",
        flagUrl: IndiaImg,
    },
    {
        value: "italy",
        label: "Italy",
        flagUrl: ItalyImg,
    },

    {
        value: "japan",
        label: "Japan",
        flagUrl: JapanImg,
    },
    {
        value: "mexico",
        label: "Mexico",
        flagUrl: MexicoImg,
    },
    {
        value: "netherland",
        label: "Netherland",
        flagUrl: NetherlandImg,
    },
    {
        value: "poland",
        label: "Poland",
        flagUrl: PolandImg,
    },
    {
        value: "saudia_arabia",
        label: "Saudia Arabia",
        flagUrl: SaudiaImg,
    },
    {
        value: "singapore",
        label: "Singapore",
        flagUrl: SindaporeImg,
    },
    {
        value: "spain",
        label: "Spain",
        flagUrl: SpainImg,
    },
    {
        value: "sweden",
        label: "Sweden",
        flagUrl: SwedenImg,
    },
    {
        value: "turkey",
        label: "Turkey",
        flagUrl: TurkeyImg,
    },
    {
        value: "uae",
        label: "UAE",
        flagUrl: UaeImg,
    },
    {
        value: "united_kindom",
        label: "United Kindom",
        flagUrl: UkImg,
    },
]

export const AsinIssuesList = [
    "DIFFERENT_ASIN_RETURNED",
    "CHILD_ASIN_RETURNED",
    "DATA_UNAVAILABLE",
    "DIFFERENT_LARGE_CATEGORY",
]

export const notificationsData = {
    notifications: [
        {
            trackpack: {
                id: 228,
                user: 93,
                name: "25 PC Test",
                status: "ACTIVE",
                amazon_tld: "com",
                large_category: "Tools & Home Improvement",
                created_at: "2024-09-25T09:27:56.155Z",
                last_updated_at: "2024-09-27T15:42:09.807Z",
                next_tracking_order: 2,
            },
            product_notifications: {
                listing_changes: [
                    {
                        notification_id: 5458,
                        read_at: null,
                        created_at: "2024-10-08T12:03:42.286Z",
                        requested_asin: "B09GLY1S79",
                        main_image_filename: "81zzS5qZZ5L._AC_SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["price", "bullets_id"],
                        total_changes: 2,
                        pd_timestamp: "2024-10-08T12:01:01.740Z",
                    },
                    {
                        notification_id: 5027,
                        read_at: null,
                        created_at: "2024-10-05T00:02:54.193Z",
                        requested_asin: "B09GLY1S79",
                        main_image_filename: "81zzS5qZZ5L._AC_SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["videos"],
                        total_changes: 1,
                        pd_timestamp: "2024-10-05T00:01:01.399Z",
                    },
                    {
                        notification_id: 5597,
                        read_at: null,
                        created_at: "2024-10-09T00:03:50.234Z",
                        requested_asin: "B09GLY1S79",
                        main_image_filename: "81zzS5qZZ5L._AC_SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["bullets_id"],
                        total_changes: 1,
                        pd_timestamp: "2024-10-09T00:00:53.708Z",
                    },
                    {
                        notification_id: 4743,
                        read_at: "2024-10-02T13:06:42.348Z",
                        created_at: "2024-10-02T00:03:45.090Z",
                        requested_asin: "B09GLY1S79",
                        main_image_filename: "81zzS5qZZ5L._AC_SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["videos"],
                        total_changes: 1,
                        pd_timestamp: "2024-10-02T00:01:37.076Z",
                    },
                    {
                        notification_id: 4767,
                        read_at: "2024-10-02T13:08:46.212Z",
                        created_at: "2024-10-02T12:05:28.436Z",
                        requested_asin: "B09GLY1S79",
                        main_image_filename: "81zzS5qZZ5L._AC_SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["videos"],
                        total_changes: 1,
                        pd_timestamp: "2024-10-02T12:00:50.939Z",
                    },
                    {
                        notification_id: 4860,
                        read_at: "2024-10-04T14:24:59.442Z",
                        created_at: "2024-10-03T12:05:34.188Z",
                        requested_asin: "B09GLY1S79",
                        main_image_filename: "81zzS5qZZ5L._AC_SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["videos"],
                        total_changes: 1,
                        pd_timestamp: "2024-10-03T12:01:10.181Z",
                    },
                    {
                        notification_id: 5087,
                        read_at: null,
                        created_at: "2024-10-05T12:07:39.517Z",
                        requested_asin: "B09GLY1S79",
                        main_image_filename: "81zzS5qZZ5L._AC_SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["videos"],
                        total_changes: 1,
                        pd_timestamp: "2024-10-05T12:02:36.755Z",
                    },
                    {
                        notification_id: 5307,
                        read_at: null,
                        created_at: "2024-10-07T12:03:40.905Z",
                        requested_asin: "B09GLY1S79",
                        main_image_filename: "81zzS5qZZ5L._AC_SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["price"],
                        total_changes: 1,
                        pd_timestamp: "2024-10-07T12:01:39.263Z",
                    },
                    {
                        notification_id: 4121,
                        read_at: null,
                        created_at: "2024-09-26T12:02:38.704Z",
                        requested_asin: "B09GLY1S79",
                        main_image_filename: "81zzS5qZZ5L._AC_SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["videos"],
                        total_changes: 1,
                        pd_timestamp: "2024-09-26T12:00:59.342Z",
                    },
                    {
                        notification_id: 4208,
                        read_at: null,
                        created_at: "2024-09-27T00:02:48.554Z",
                        requested_asin: "B09GLY1S79",
                        main_image_filename: "81zzS5qZZ5L._AC_SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["bullets_id"],
                        total_changes: 1,
                        pd_timestamp: "2024-09-27T00:01:06.072Z",
                    },
                    {
                        notification_id: 4232,
                        read_at: null,
                        created_at: "2024-09-27T12:06:39.107Z",
                        requested_asin: "B09GLY1S79",
                        main_image_filename: "81zzS5qZZ5L._AC_SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["bullets_id"],
                        total_changes: 1,
                        pd_timestamp: "2024-09-27T12:01:20.349Z",
                    },
                    {
                        notification_id: 4579,
                        read_at: "2024-10-02T14:27:19.816Z",
                        created_at: "2024-09-30T12:02:46.698Z",
                        requested_asin: "B09GLY1S79",
                        main_image_filename: "81zzS5qZZ5L._AC_SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["price", "videos"],
                        total_changes: 2,
                        pd_timestamp: "2024-09-30T12:01:04.698Z",
                    },
                    {
                        notification_id: 5572,
                        read_at: null,
                        created_at: "2024-10-09T00:03:47.793Z",
                        requested_asin: "B08V8M6ZYG",
                        main_image_filename: "61sZ7nfTASL._SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["price"],
                        total_changes: 1,
                        pd_timestamp: "2024-10-09T00:00:50.015Z",
                    },
                    {
                        notification_id: 4422,
                        read_at: null,
                        created_at: "2024-09-29T12:05:20.682Z",
                        requested_asin: "B08V8M6ZYG",
                        main_image_filename: "61sZ7nfTASL._SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["videos"],
                        total_changes: 1,
                        pd_timestamp: "2024-09-29T12:03:16.042Z",
                    },
                    {
                        notification_id: 5660,
                        read_at: null,
                        created_at: "2024-10-09T12:04:38.671Z",
                        requested_asin: "B08V8M6ZYG",
                        main_image_filename: "61sZ7nfTASL._SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["price"],
                        total_changes: 1,
                        pd_timestamp: "2024-10-09T12:00:59.398Z",
                    },
                    {
                        notification_id: 4919,
                        read_at: "2024-10-04T14:24:54.701Z",
                        created_at: "2024-10-04T00:03:47.550Z",
                        requested_asin: "B08V8M6ZYG",
                        main_image_filename: "61sZ7nfTASL._SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["videos"],
                        total_changes: 1,
                        pd_timestamp: "2024-10-04T00:01:14.807Z",
                    },
                    {
                        notification_id: 4726,
                        read_at: "2024-10-02T11:41:51.581Z",
                        created_at: "2024-10-02T00:03:42.803Z",
                        requested_asin: "B08V8M6ZYG",
                        main_image_filename: "61sZ7nfTASL._SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: [],
                        total_changes: 0,
                        pd_timestamp: "2024-10-02T00:01:07.373Z",
                    },
                    {
                        notification_id: 4850,
                        read_at: "2024-10-04T14:24:59.442Z",
                        created_at: "2024-10-03T12:05:32.490Z",
                        requested_asin: "B08V8M6ZYG",
                        main_image_filename: "61sZ7nfTASL._SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["videos"],
                        total_changes: 1,
                        pd_timestamp: "2024-10-03T12:01:05.619Z",
                    },
                    {
                        notification_id: 4104,
                        read_at: null,
                        created_at: "2024-09-26T12:02:36.220Z",
                        requested_asin: "B08V8M6ZYG",
                        main_image_filename: "61sZ7nfTASL._SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["videos"],
                        total_changes: 1,
                        pd_timestamp: "2024-09-26T12:00:52.306Z",
                    },
                    {
                        notification_id: 4205,
                        read_at: null,
                        created_at: "2024-09-27T00:02:48.373Z",
                        requested_asin: "B08V8M6ZYG",
                        main_image_filename: "61sZ7nfTASL._SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["videos"],
                        total_changes: 1,
                        pd_timestamp: "2024-09-27T00:01:05.369Z",
                    },
                    {
                        notification_id: 4221,
                        read_at: null,
                        created_at: "2024-09-27T12:06:37.373Z",
                        requested_asin: "B08V8M6ZYG",
                        main_image_filename: "61sZ7nfTASL._SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["videos"],
                        total_changes: 1,
                        pd_timestamp: "2024-09-27T12:00:44.975Z",
                    },
                    {
                        notification_id: 4349,
                        read_at: null,
                        created_at: "2024-09-28T12:02:34.577Z",
                        requested_asin: "B08V8M6ZYG",
                        main_image_filename: "61sZ7nfTASL._SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["videos"],
                        total_changes: 1,
                        pd_timestamp: "2024-09-28T12:00:47.860Z",
                    },
                    {
                        notification_id: 4042,
                        read_at: null,
                        created_at: "2024-09-26T00:02:28.566Z",
                        requested_asin: "B08V8M6ZYG",
                        main_image_filename: "61sZ7nfTASL._SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["videos"],
                        total_changes: 1,
                        pd_timestamp: "2024-09-26T00:00:55.258Z",
                    },
                ],
                setup_alerts_and_issues: [],
            },
        },
        {
            trackpack: {
                id: 167,
                user: 93,
                name: "Amaizing Sushi Kit",
                status: "ACTIVE",
                amazon_tld: "fr",
                large_category: "Cuisine et Maison",
                created_at: "2024-09-02T09:27:52.423Z",
                last_updated_at: "2024-09-27T15:42:09.277Z",
                next_tracking_order: 7,
            },
            product_notifications: {
                listing_changes: [
                    {
                        notification_id: 5561,
                        read_at: null,
                        created_at: "2024-10-09T00:03:46.330Z",
                        requested_asin: "B0D41HGKKL",
                        main_image_filename: "710nRqp5zVL._AC_SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["price"],
                        total_changes: 1,
                        pd_timestamp: "2024-10-09T00:00:47.153Z",
                    },
                ],
                setup_alerts_and_issues: [],
            },
        },
        {
            trackpack: {
                id: 194,
                user: 93,
                name: "Test Amaizing Sushi Kit",
                status: "ACTIVE",
                amazon_tld: "fr",
                large_category: "Cuisine et Maison",
                created_at: "2024-09-11T08:25:40.537Z",
                last_updated_at: "2024-09-27T15:42:09.638Z",
                next_tracking_order: 3,
            },
            product_notifications: {
                listing_changes: [
                    {
                        notification_id: 5563,
                        read_at: null,
                        created_at: "2024-10-09T00:03:46.334Z",
                        requested_asin: "B0D41HGKKL",
                        main_image_filename: "710nRqp5zVL._AC_SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["price"],
                        total_changes: 1,
                        pd_timestamp: "2024-10-09T00:00:47.153Z",
                    },
                ],
                setup_alerts_and_issues: [],
            },
        },
        {
            trackpack: {
                id: 179,
                user: 93,
                name: "Hamza test 2",
                status: "ACTIVE",
                amazon_tld: "fr",
                large_category: "Cuisine et Maison",
                created_at: "2024-09-04T11:52:29.714Z",
                last_updated_at: "2024-09-27T15:42:09.496Z",
                next_tracking_order: 1,
            },
            product_notifications: {
                listing_changes: [
                    {
                        notification_id: 5564,
                        read_at: null,
                        created_at: "2024-10-09T00:03:46.337Z",
                        requested_asin: "B0D41HGKKL",
                        main_image_filename: "710nRqp5zVL._AC_SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["price"],
                        total_changes: 1,
                        pd_timestamp: "2024-10-09T00:00:47.153Z",
                    },
                ],
                setup_alerts_and_issues: [],
            },
        },
        {
            trackpack: {
                id: 167,
                user: 93,
                name: "Amaizing Sushi Kit",
                status: "ACTIVE",
                amazon_tld: "fr",
                large_category: "Cuisine et Maison",
                created_at: "2024-09-02T09:27:52.423Z",
                last_updated_at: "2024-09-27T15:42:09.277Z",
                next_tracking_order: 7,
            },
            product_notifications: {
                listing_changes: [
                    {
                        notification_id: 5242,
                        read_at: null,
                        created_at: "2024-10-07T00:06:22.106Z",
                        requested_asin: "B0D41HGKKL",
                        main_image_filename: "710nRqp5zVL._AC_SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["price"],
                        total_changes: 1,
                        pd_timestamp: "2024-10-07T00:01:04.949Z",
                    },
                ],
                setup_alerts_and_issues: [],
            },
        },
        {
            trackpack: {
                id: 194,
                user: 93,
                name: "Test Amaizing Sushi Kit",
                status: "ACTIVE",
                amazon_tld: "fr",
                large_category: "Cuisine et Maison",
                created_at: "2024-09-11T08:25:40.537Z",
                last_updated_at: "2024-09-27T15:42:09.638Z",
                next_tracking_order: 3,
            },
            product_notifications: {
                listing_changes: [
                    {
                        notification_id: 5244,
                        read_at: null,
                        created_at: "2024-10-07T00:06:22.109Z",
                        requested_asin: "B0D41HGKKL",
                        main_image_filename: "710nRqp5zVL._AC_SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["price"],
                        total_changes: 1,
                        pd_timestamp: "2024-10-07T00:01:04.949Z",
                    },
                ],
                setup_alerts_and_issues: [],
            },
        },
        {
            trackpack: {
                id: 179,
                user: 93,
                name: "Hamza test 2",
                status: "ACTIVE",
                amazon_tld: "fr",
                large_category: "Cuisine et Maison",
                created_at: "2024-09-04T11:52:29.714Z",
                last_updated_at: "2024-09-27T15:42:09.496Z",
                next_tracking_order: 1,
            },
            product_notifications: {
                listing_changes: [
                    {
                        notification_id: 5245,
                        read_at: null,
                        created_at: "2024-10-07T00:06:22.111Z",
                        requested_asin: "B0D41HGKKL",
                        main_image_filename: "710nRqp5zVL._AC_SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["price"],
                        total_changes: 1,
                        pd_timestamp: "2024-10-07T00:01:04.949Z",
                    },
                ],
                setup_alerts_and_issues: [],
            },
        },
        {
            trackpack: {
                id: 167,
                user: 93,
                name: "Amaizing Sushi Kit",
                status: "ACTIVE",
                amazon_tld: "fr",
                large_category: "Cuisine et Maison",
                created_at: "2024-09-02T09:27:52.423Z",
                last_updated_at: "2024-09-27T15:42:09.277Z",
                next_tracking_order: 7,
            },
            product_notifications: {
                listing_changes: [
                    {
                        notification_id: 4466,
                        read_at: "2024-10-01T16:30:17.896Z",
                        created_at: "2024-09-30T00:02:36.809Z",
                        requested_asin: "B0D41HGKKL",
                        main_image_filename: "710nRqp5zVL._AC_SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["price"],
                        total_changes: 1,
                        pd_timestamp: "2024-09-30T00:00:43.032Z",
                    },
                ],
                setup_alerts_and_issues: [],
            },
        },
        {
            trackpack: {
                id: 179,
                user: 93,
                name: "Hamza test 2",
                status: "ACTIVE",
                amazon_tld: "fr",
                large_category: "Cuisine et Maison",
                created_at: "2024-09-04T11:52:29.714Z",
                last_updated_at: "2024-09-27T15:42:09.496Z",
                next_tracking_order: 1,
            },
            product_notifications: {
                listing_changes: [
                    {
                        notification_id: 4469,
                        read_at: null,
                        created_at: "2024-09-30T00:02:36.814Z",
                        requested_asin: "B0D41HGKKL",
                        main_image_filename: "710nRqp5zVL._AC_SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["price"],
                        total_changes: 1,
                        pd_timestamp: "2024-09-30T00:00:43.032Z",
                    },
                ],
                setup_alerts_and_issues: [],
            },
        },
        {
            trackpack: {
                id: 194,
                user: 93,
                name: "Test Amaizing Sushi Kit",
                status: "ACTIVE",
                amazon_tld: "fr",
                large_category: "Cuisine et Maison",
                created_at: "2024-09-11T08:25:40.537Z",
                last_updated_at: "2024-09-27T15:42:09.638Z",
                next_tracking_order: 3,
            },
            product_notifications: {
                listing_changes: [
                    {
                        notification_id: 4468,
                        read_at: "2024-10-01T16:03:38.796Z",
                        created_at: "2024-09-30T00:02:36.812Z",
                        requested_asin: "B0D41HGKKL",
                        main_image_filename: "710nRqp5zVL._AC_SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["price"],
                        total_changes: 1,
                        pd_timestamp: "2024-09-30T00:00:43.032Z",
                    },
                    {
                        notification_id: 4884,
                        read_at: null,
                        created_at: "2024-10-04T00:03:42.988Z",
                        requested_asin: "B0BH519KQ5",
                        main_image_filename: "81jiA7HfUzL._AC_SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["price"],
                        total_changes: 1,
                        pd_timestamp: "2024-10-04T00:01:01.806Z",
                    },
                    {
                        notification_id: 5351,
                        read_at: null,
                        created_at: "2024-10-08T00:04:48.857Z",
                        requested_asin: "B0BH519KQ5",
                        main_image_filename: "81jiA7HfUzL._AC_SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["price"],
                        total_changes: 1,
                        pd_timestamp: "2024-10-08T00:01:39.771Z",
                    },
                    {
                        notification_id: 4497,
                        read_at: "2024-10-01T16:03:38.796Z",
                        created_at: "2024-09-30T00:02:39.220Z",
                        requested_asin: "B0BH519KQ5",
                        main_image_filename: "81jiA7HfUzL._AC_SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["price"],
                        total_changes: 1,
                        pd_timestamp: "2024-09-30T00:00:52.028Z",
                    },
                ],
                setup_alerts_and_issues: [],
            },
        },
        {
            trackpack: {
                id: 167,
                user: 93,
                name: "Amaizing Sushi Kit",
                status: "ACTIVE",
                amazon_tld: "fr",
                large_category: "Cuisine et Maison",
                created_at: "2024-09-02T09:27:52.423Z",
                last_updated_at: "2024-09-27T15:42:09.277Z",
                next_tracking_order: 7,
            },
            product_notifications: {
                listing_changes: [
                    {
                        notification_id: 5371,
                        read_at: null,
                        created_at: "2024-10-08T00:04:50.369Z",
                        requested_asin: "B08MZPXSK4",
                        main_image_filename: "71U3bUx8mOL._AC_SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["availability_id"],
                        total_changes: 1,
                        pd_timestamp: "2024-10-08T00:02:17.680Z",
                    },
                    {
                        notification_id: 4199,
                        read_at: "2024-10-01T16:30:17.896Z",
                        created_at: "2024-09-27T00:02:48.177Z",
                        requested_asin: "B08MZPXSK4",
                        main_image_filename: "71U3bUx8mOL._AC_SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["availability_id"],
                        total_changes: 1,
                        pd_timestamp: "2024-09-27T00:01:04.898Z",
                    },
                ],
                setup_alerts_and_issues: [],
            },
        },
        {
            trackpack: {
                id: 231,
                user: 93,
                name: "Hello Test TP",
                status: "ACTIVE",
                amazon_tld: "com",
                large_category: "Sports & Outdoors",
                created_at: "2024-10-08T12:17:15.281Z",
                last_updated_at: "2024-10-08T13:58:59.757Z",
                next_tracking_order: 4,
            },
            product_notifications: {
                listing_changes: [],
                setup_alerts_and_issues: [
                    {
                        notification_id: 5538,
                        read_at: null,
                        created_at: "2024-10-08T12:20:17.509Z",
                        requested_asin: "B0C5RZW6ZQ",
                        main_image_filename: "81KzJ+TQRZL._AC_SL1500_.jpg",
                        type: "ASIN_READY",
                        context: "NEW_TP",
                    },
                ],
            },
        },
        {
            trackpack: {
                id: 192,
                user: 93,
                name: "Amaizing Soft Fishing Lures",
                status: "ACTIVE",
                amazon_tld: "com",
                large_category: "Sports & Outdoors",
                created_at: "2024-09-11T08:07:54.900Z",
                last_updated_at: "2024-09-27T15:42:09.618Z",
                next_tracking_order: 3,
            },
            product_notifications: {
                listing_changes: [
                    {
                        notification_id: 5437,
                        read_at: null,
                        created_at: "2024-10-08T12:03:41.686Z",
                        requested_asin: "B09H5RGNSP",
                        main_image_filename: "710nmSrFuaL._AC_SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["price"],
                        total_changes: 1,
                        pd_timestamp: "2024-10-08T12:01:00.143Z",
                    },
                ],
                setup_alerts_and_issues: [],
            },
        },
        {
            trackpack: {
                id: 229,
                user: 93,
                name: "Amaizing Soft Fishing Lures test",
                status: "ACTIVE",
                amazon_tld: "com",
                large_category: "Sports & Outdoors",
                created_at: "2024-09-26T10:16:25.709Z",
                last_updated_at: "2024-09-27T15:42:09.786Z",
                next_tracking_order: 2,
            },
            product_notifications: {
                listing_changes: [
                    {
                        notification_id: 5442,
                        read_at: null,
                        created_at: "2024-10-08T12:03:41.694Z",
                        requested_asin: "B09H5RGNSP",
                        main_image_filename: "710nmSrFuaL._AC_SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["price"],
                        total_changes: 1,
                        pd_timestamp: "2024-10-08T12:01:00.143Z",
                    },
                ],
                setup_alerts_and_issues: [],
            },
        },
        {
            trackpack: {
                id: 231,
                user: 93,
                name: "Hello Test TP",
                status: "ACTIVE",
                amazon_tld: "com",
                large_category: "Sports & Outdoors",
                created_at: "2024-10-08T12:17:15.281Z",
                last_updated_at: "2024-10-08T13:58:59.757Z",
                next_tracking_order: 4,
            },
            product_notifications: {
                listing_changes: [],
                setup_alerts_and_issues: [
                    {
                        notification_id: 5539,
                        read_at: null,
                        created_at: "2024-10-08T12:49:14.076Z",
                        requested_asin: "B09H5RGNSP",
                        main_image_filename: "710nmSrFuaL._AC_SL1500_.jpg",
                        type: "ASIN_READY",
                        context: "ASINS_ADDED",
                    },
                ],
            },
        },
        {
            trackpack: {
                id: 229,
                user: 93,
                name: "Amaizing Soft Fishing Lures test",
                status: "ACTIVE",
                amazon_tld: "com",
                large_category: "Sports & Outdoors",
                created_at: "2024-09-26T10:16:25.709Z",
                last_updated_at: "2024-09-27T15:42:09.786Z",
                next_tracking_order: 2,
            },
            product_notifications: {
                listing_changes: [],
                setup_alerts_and_issues: [
                    {
                        notification_id: 4078,
                        read_at: "2024-10-04T09:52:18.655Z",
                        created_at: "2024-09-26T10:19:08.255Z",
                        requested_asin: "B09H5RGNSP",
                        main_image_filename: "710nmSrFuaL._AC_SL1500_.jpg",
                        type: "ASIN_READY",
                        context: "ASINS_ADDED",
                    },
                ],
            },
        },
        {
            trackpack: {
                id: 193,
                user: 93,
                name: "Amaizing Air Fryers test",
                status: "ACTIVE",
                amazon_tld: "com",
                large_category: "Kitchen & Dining",
                created_at: "2024-09-11T08:17:12.574Z",
                last_updated_at: "2024-09-27T15:42:09.629Z",
                next_tracking_order: 3,
            },
            product_notifications: {
                listing_changes: [
                    {
                        notification_id: 4983,
                        read_at: null,
                        created_at: "2024-10-05T00:02:48.645Z",
                        requested_asin: "B0B3RXVRW1",
                        main_image_filename: "61-Nxh+TjkL._AC_SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: [
                            "videos",
                            "title_id",
                            "bullets_id",
                            "main_image_id",
                            "actual_asin_id",
                            "availability_id",
                            "carousel_images_id",
                        ],
                        total_changes: 7,
                        pd_timestamp: "2024-10-05T00:00:46.460Z",
                    },
                    {
                        notification_id: 4819,
                        read_at: "2024-10-04T12:54:44.678Z",
                        created_at: "2024-10-03T00:03:45.719Z",
                        requested_asin: "B0B3RXVRW1",
                        main_image_filename: "61-Nxh+TjkL._AC_SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: [
                            "price",
                            "videos",
                            "title_id",
                            "bullets_id",
                            "main_image_id",
                            "actual_asin_id",
                            "availability_id",
                            "carousel_images_id",
                        ],
                        total_changes: 8,
                        pd_timestamp: "2024-10-03T00:01:19.825Z",
                    },
                    {
                        notification_id: 5283,
                        read_at: null,
                        created_at: "2024-10-07T12:03:38.546Z",
                        requested_asin: "B0B3RXVRW1",
                        main_image_filename: "61-Nxh+TjkL._AC_SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["videos"],
                        total_changes: 1,
                        pd_timestamp: "2024-10-07T12:01:13.400Z",
                    },
                    {
                        notification_id: 4155,
                        read_at: "2024-10-01T16:03:38.796Z",
                        created_at: "2024-09-27T00:02:43.930Z",
                        requested_asin: "B0B3RXVRW1",
                        main_image_filename: "61-Nxh+TjkL._AC_SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["price", "seller_id"],
                        total_changes: 2,
                        pd_timestamp: "2024-09-27T00:00:52.310Z",
                    },
                    {
                        notification_id: 4291,
                        read_at: "2024-10-01T16:03:38.796Z",
                        created_at: "2024-09-28T00:02:48.140Z",
                        requested_asin: "B0B3RXVRW1",
                        main_image_filename: "61-Nxh+TjkL._AC_SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["price", "seller_id"],
                        total_changes: 2,
                        pd_timestamp: "2024-09-28T00:01:02.764Z",
                    },
                    {
                        notification_id: 4340,
                        read_at: "2024-10-01T16:03:38.796Z",
                        created_at: "2024-09-28T12:02:34.272Z",
                        requested_asin: "B0B3RXVRW1",
                        main_image_filename: "61-Nxh+TjkL._AC_SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: [
                            "videos",
                            "title_id",
                            "bullets_id",
                            "main_image_id",
                            "actual_asin_id",
                            "availability_id",
                            "carousel_images_id",
                        ],
                        total_changes: 7,
                        pd_timestamp: "2024-09-28T12:00:45.638Z",
                    },
                    {
                        notification_id: 4063,
                        read_at: "2024-10-01T16:22:15.550Z",
                        created_at: "2024-09-26T00:02:29.972Z",
                        requested_asin: "B0B3RXVRW1",
                        main_image_filename: "61-Nxh+TjkL._AC_SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["videos"],
                        total_changes: 1,
                        pd_timestamp: "2024-09-26T00:00:58.888Z",
                    },
                    {
                        notification_id: 4132,
                        read_at: "2024-10-01T16:22:15.550Z",
                        created_at: "2024-09-26T12:02:39.109Z",
                        requested_asin: "B0B3RXVRW1",
                        main_image_filename: "61-Nxh+TjkL._AC_SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["videos"],
                        total_changes: 1,
                        pd_timestamp: "2024-09-26T12:01:02.922Z",
                    },
                ],
                setup_alerts_and_issues: [
                    {
                        notification_id: 5513,
                        read_at: null,
                        created_at: "2024-10-08T12:03:46.005Z",
                        requested_asin: "B0D3JGCLFR",
                        main_image_filename: "61g41IPyG2L._AC_SL1500_.jpg",
                        type: "DATA_UNAVAILABLE",
                        context: "REGULAR_TRACKING",
                    },
                    {
                        notification_id: 5647,
                        read_at: null,
                        created_at: "2024-10-09T00:03:53.581Z",
                        requested_asin: "B0D3JGCLFR",
                        main_image_filename: "61g41IPyG2L._AC_SL1500_.jpg",
                        type: "DATA_UNAVAILABLE",
                        context: "REGULAR_TRACKING",
                    },
                    {
                        notification_id: 5729,
                        read_at: null,
                        created_at: "2024-10-09T12:04:45.467Z",
                        requested_asin: "B0D3JGCLFR",
                        main_image_filename: "61g41IPyG2L._AC_SL1500_.jpg",
                        type: "DATA_UNAVAILABLE",
                        context: "REGULAR_TRACKING",
                    },
                ],
            },
        },
        {
            trackpack: {
                id: 195,
                user: 93,
                name: "Amaizing Dog Bone Broth",
                status: "ACTIVE",
                amazon_tld: "co.uk",
                large_category: "Pet Supplies",
                created_at: "2024-09-11T08:49:35.247Z",
                last_updated_at: "2024-09-27T15:42:09.661Z",
                next_tracking_order: 3,
            },
            product_notifications: {
                listing_changes: [
                    {
                        notification_id: 5154,
                        read_at: null,
                        created_at: "2024-10-06T12:04:25.584Z",
                        requested_asin: "B0CN3PPHGT",
                        main_image_filename: "81YJjU0mdqL._AC_SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["availability_id"],
                        total_changes: 1,
                        pd_timestamp: "2024-10-06T12:01:30.325Z",
                    },
                ],
                setup_alerts_and_issues: [],
            },
        },
        {
            trackpack: {
                id: 175,
                user: 93,
                name: "Amaizing Dog Bone Broth",
                status: "ACTIVE",
                amazon_tld: "co.uk",
                large_category: "Pet Supplies",
                created_at: "2024-09-04T07:08:19.407Z",
                last_updated_at: "2024-10-03T14:22:59.343Z",
                next_tracking_order: 3,
            },
            product_notifications: {
                listing_changes: [
                    {
                        notification_id: 5156,
                        read_at: null,
                        created_at: "2024-10-06T12:04:25.588Z",
                        requested_asin: "B0CN3PPHGT",
                        main_image_filename: "81YJjU0mdqL._AC_SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["availability_id"],
                        total_changes: 1,
                        pd_timestamp: "2024-10-06T12:01:30.325Z",
                    },
                ],
                setup_alerts_and_issues: [],
            },
        },
        {
            trackpack: {
                id: 229,
                user: 93,
                name: "Amaizing Soft Fishing Lures test",
                status: "ACTIVE",
                amazon_tld: "com",
                large_category: "Sports & Outdoors",
                created_at: "2024-09-26T10:16:25.709Z",
                last_updated_at: "2024-09-27T15:42:09.786Z",
                next_tracking_order: 2,
            },
            product_notifications: {
                listing_changes: [],
                setup_alerts_and_issues: [
                    {
                        notification_id: 4079,
                        read_at: null,
                        created_at: "2024-09-26T10:19:08.271Z",
                        requested_asin: "B0CWY6BLFC",
                        main_image_filename: "81gjenh4OIL._AC_SL1500_.jpg",
                        type: "ASIN_READY",
                        context: "ASINS_ADDED",
                    },
                ],
            },
        },
        {
            trackpack: {
                id: 231,
                user: 93,
                name: "Hello Test TP",
                status: "ACTIVE",
                amazon_tld: "com",
                large_category: "Sports & Outdoors",
                created_at: "2024-10-08T12:17:15.281Z",
                last_updated_at: "2024-10-08T13:58:59.757Z",
                next_tracking_order: 4,
            },
            product_notifications: {
                listing_changes: [],
                setup_alerts_and_issues: [
                    {
                        notification_id: 5540,
                        read_at: null,
                        created_at: "2024-10-08T14:01:22.442Z",
                        requested_asin: "B0CWY6BLFC",
                        main_image_filename: "81gjenh4OIL._AC_SL1500_.jpg",
                        type: "ASIN_READY",
                        context: "ASINS_ADDED",
                    },
                ],
            },
        },
        {
            trackpack: {
                id: 189,
                user: 93,
                name: "Amaizing Air Rifles",
                status: "ACTIVE",
                amazon_tld: "com",
                large_category: "Sports & Outdoors",
                created_at: "2024-09-11T07:43:18.689Z",
                last_updated_at: "2024-09-27T15:42:09.590Z",
                next_tracking_order: 4,
            },
            product_notifications: {
                listing_changes: [
                    {
                        notification_id: 4537,
                        read_at: "2024-10-02T11:42:01.581Z",
                        created_at: "2024-09-30T12:02:42.790Z",
                        requested_asin: "B00JRHKAO8",
                        main_image_filename: "61DxOzWcK8L._AC_SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["price", "seller_id"],
                        total_changes: 2,
                        pd_timestamp: "2024-09-30T12:00:56.040Z",
                    },
                    {
                        notification_id: 4307,
                        read_at: "2024-10-02T11:42:01.581Z",
                        created_at: "2024-09-28T00:02:49.446Z",
                        requested_asin: "B08SJF3FQB",
                        main_image_filename: "51wUdCnqZVL._AC_SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["bullets_id"],
                        total_changes: 1,
                        pd_timestamp: "2024-09-28T00:01:05.075Z",
                    },
                    {
                        notification_id: 4785,
                        read_at: "2024-10-03T12:04:17.443Z",
                        created_at: "2024-10-03T00:03:39.117Z",
                        requested_asin: "B08SJF3FQB",
                        main_image_filename: "51wUdCnqZVL._AC_SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["availability_id"],
                        total_changes: 1,
                        pd_timestamp: "2024-10-03T00:00:52.919Z",
                    },
                    {
                        notification_id: 5116,
                        read_at: null,
                        created_at: "2024-10-06T00:03:55.963Z",
                        requested_asin: "B08SJF3FQB",
                        main_image_filename: "51wUdCnqZVL._AC_SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["price", "seller_id", "availability_id"],
                        total_changes: 3,
                        pd_timestamp: "2024-10-06T00:01:09.238Z",
                    },
                    {
                        notification_id: 5161,
                        read_at: null,
                        created_at: "2024-10-06T12:04:26.672Z",
                        requested_asin: "B08SJF3FQB",
                        main_image_filename: "51wUdCnqZVL._AC_SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["price", "seller_id", "availability_id"],
                        total_changes: 3,
                        pd_timestamp: "2024-10-06T12:01:34.658Z",
                    },
                    {
                        notification_id: 5362,
                        read_at: null,
                        created_at: "2024-10-08T00:04:50.183Z",
                        requested_asin: "B08SJF3FQB",
                        main_image_filename: "51wUdCnqZVL._AC_SL1500_.jpg",
                        type: "LISTING_CHANGE",
                        context: "REGULAR_TRACKING",
                        changes: ["availability_id"],
                        total_changes: 1,
                        pd_timestamp: "2024-10-08T00:02:16.044Z",
                    },
                ],
                setup_alerts_and_issues: [],
            },
        },
        {
            trackpack: {
                id: 231,
                user: 93,
                name: "Hello Test TP",
                status: "ACTIVE",
                amazon_tld: "com",
                large_category: "Sports & Outdoors",
                created_at: "2024-10-08T12:17:15.281Z",
                last_updated_at: "2024-10-08T13:58:59.757Z",
                next_tracking_order: 4,
            },
            product_notifications: {
                listing_changes: [],
                setup_alerts_and_issues: [
                    {
                        notification_id: 5541,
                        read_at: null,
                        created_at: "2024-10-08T14:01:22.454Z",
                        requested_asin: "B0D2Z1HWT7",
                        main_image_filename: "61ZXAzeMQGL._AC_SL1080_.jpg",
                        type: "ASIN_READY",
                        context: "ASINS_ADDED",
                    },
                ],
            },
        },
    ],
}

export const competitorsDataList = {
    "category": {
        "name": "Air Fryers",
        "link": "https://www.amazon.com/gp/bestsellers/kitchen/17659096011/ref=pd_zg_hrsr_kitchen"
    },
    "target_product": {
        "asin": "B08R7TC3JJ",
        "tld": "com",
        "title": "Gourmia Air Fryer Oven Digital Display 5 Quart Large AirFryer Cooker 12 Touch Cooking Presets, XL Air Fryer Basket 1500w Power Multifunction Stainless Steel FRY FORCE 360° (5 QT)",
        "image_url": "https://m.media-amazon.com/images/I/71E1HtofnzL._AC_SL1500_.jpg",
        "rating": 4.5,
        "ratings_total": 142,
        "price": {
            "symbol": "$",
            "value": 53.99,
            "currency": "USD",
            "raw": "$53.99"
        }
    },
    "bestsellers": [
        {
            "asin": "B07FDJMC9Q",
            "title": "Ninja Air Fryer, Roast, Bake, Air Fry, Roast, Broil, Reheats, & Dehydrates, 4-in-1, Fries, Frozen Food, Veggies, and Juicy Me",
            "rank": 1,
            "url": "https://www.amazon.com/Ninja-AF101-Fryer-Black-gray/dp/B07FDJMC9Q/ref=zg_bs_g_17659096011_d_sccl_1/147-7295122-3128747?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/71+8uTMDRFL._AC_UL300_SR300,200_.jpg",
            "rating": 4.7,
            "ratings_total": 82003,
            "price": {
                "symbol": "$",
                "value": 89.99,
                "currency": "USD",
                "raw": "$89.99"
            }
        },
        {
            "asin": "B0CSZ7WBYW",
            "title": "Ninja Air Fryer Pro 4-in-1 with 5 QT Capacity, Air Fry, Roast, Reheat, Dehydrate, Air Crisp Technology with 400F for hot, cri",
            "rank": 2,
            "url": "https://www.amazon.com/Ninja-Capacity-Dehydrate-Technology-AF141/dp/B0CSZ7WBYW/ref=zg_bs_g_17659096011_d_sccl_2/147-7295122-3128747?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/71QwoGmcfUL._AC_UL300_SR300,200_.jpg",
            "rating": 4.7,
            "ratings_total": 1518,
            "price": {
                "symbol": "$",
                "value": 89.99,
                "currency": "USD",
                "raw": "$89.99"
            }
        },
        {
            "asin": "B0CGMFGX87",
            "title": "Chefman 12-Quart 6-in-1 Air Fryer Oven with Digital Timer, Touchscreen, and 12 Presets - Family Size Countertop Convection Ov",
            "rank": 3,
            "url": "https://www.amazon.com/Chefman-12-Quart-Digital-Touchscreen-Presets/dp/B0CGMFGX87/ref=zg_bs_g_17659096011_d_sccl_3/147-7295122-3128747?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/51UxNtqlxYL._AC_UL300_SR300,200_.jpg",
            "rating": 4.3,
            "ratings_total": 40249,
            "price": {
                "symbol": "$",
                "value": 89.99,
                "currency": "USD",
                "raw": "$89.99"
            }
        },
        {
            "asin": "B0BDFRZX3F",
            "title": "COSORI 2.1Qt Air Fryer, Small 4-in-1 Air Fryer Perfect for Simple Meals and Snack, Easy to Leftover Food to Crispy, 97% Less ",
            "rank": 4,
            "url": "https://www.amazon.com/COSORI-Space-saving-Low-noise-Dishwasher-Reference/dp/B0BDFRZX3F/ref=zg_bs_g_17659096011_d_sccl_4/147-7295122-3128747?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/71+a5I3o9VL._AC_UL300_SR300,200_.jpg",
            "rating": 4.6,
            "ratings_total": 6032,
            "price": {
                "symbol": "$",
                "value": 39.99,
                "currency": "USD",
                "raw": "$39.99"
            }
        },
        {
            "asin": "B0C33CHG99",
            "title": "COSORI Air Fryer 9-in-1, Compact & Large 6-Qt, Fast Turbo Modes, 90-450°F Evenly Results with Precise Temperature Control, Le",
            "rank": 5,
            "url": "https://www.amazon.com/COSORI-Compact-Recipes-Dehydrate-Dishwasher/dp/B0C33CHG99/ref=zg_bs_g_17659096011_d_sccl_5/147-7295122-3128747?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/81YlVdZBmaL._AC_UL300_SR300,200_.jpg",
            "rating": 4.8,
            "ratings_total": 5982,
            "price": {
                "symbol": "$",
                "value": 119.99,
                "currency": "USD",
                "raw": "$119.99"
            }
        },
        {
            "asin": "B0CL12QNNK",
            "title": "Ninja Air Fryer, Roast, Bake, Air Fry, Crisps, Broil, Reheats, Keep Warm, 10-in-1, Fries, Frozen Food, Veggies, and Meat, Qui",
            "rank": 6,
            "url": "https://www.amazon.com/Ninja-DZ302-DualZone-Independent-Dehydrate/dp/B0CL12QNNK/ref=zg_bs_g_17659096011_d_sccl_6/147-7295122-3128747?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/61kZPzjt04L._AC_UL300_SR300,200_.jpg",
            "rating": 4.8,
            "ratings_total": 35004,
            "price": {
                "symbol": "$",
                "value": 209.99,
                "currency": "USD",
                "raw": "$209.99"
            }
        },
        {
            "asin": "B08DKYBTPH",
            "title": "Chefman TurboFry® Touch Air Fryer, XL 8-Qt Family Size, One-Touch Digital Control Presets, French Fries, Chicken, Meat, Fish,",
            "rank": 7,
            "url": "https://www.amazon.com/Chefman-TurboFry-Air-Fryer-Dishwasher/dp/B08DKYBTPH/ref=zg_bs_g_17659096011_d_sccl_7/147-7295122-3128747?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/71pnG-oB2zL._AC_UL300_SR300,200_.jpg",
            "rating": 4.6,
            "ratings_total": 13160,
            "price": {
                "symbol": "$",
                "value": 84.99,
                "currency": "USD",
                "raw": "$84.99"
            }
        },
        {
            "asin": "B09B7SB46R",
            "title": "Emeril Lagasse 26 QT Extra Large Air Fryer, Convection Toaster Oven with French Doors, Stainless Steel",
            "rank": 8,
            "url": "https://www.amazon.com/Emeril-Lagasse-Convection-Toaster-Stainless/dp/B09B7SB46R/ref=zg_bs_g_17659096011_d_sccl_8/147-7295122-3128747?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/811r4Po5kXL._AC_UL300_SR300,200_.jpg",
            "rating": 4.6,
            "ratings_total": 8361,
            "price": {
                "symbol": "$",
                "value": 185.54,
                "currency": "USD",
                "raw": "$185.54"
            }
        },
        {
            "asin": "B0DDDD8WD6",
            "title": "Ninja Crispi Air Fryer, Microwave, Freezer & Dishwasher Safe, Non Toxic Glass Portable Cooking System, 4 QT & 6 Cup Glass Con",
            "rank": 9,
            "url": "https://www.amazon.com/Ninja-Microwave-Dishwasher-Containers-FN101GY/dp/B0DDDD8WD6/ref=zg_bs_g_17659096011_d_sccl_9/147-7295122-3128747?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/71ikTqH2kiL._AC_UL300_SR300,200_.jpg",
            "rating": 4.9,
            "ratings_total": 20,
            "price": {
                "symbol": "$",
                "value": 159.99,
                "currency": "USD",
                "raw": "$159.99"
            }
        },
        {
            "asin": "B0936FGLQS",
            "title": "COSORI Air Fryer 5 Qt, Max 450F for Juicy Meat, Veggies, Frozen Food, Glass Touch Panel, 130+ In-App Recipes, Little to No Oi",
            "rank": 10,
            "url": "https://www.amazon.com/COSORI-Functions-Nutrition-Recipes-Leftovers/dp/B0936FGLQS/ref=zg_bs_g_17659096011_d_sccl_10/147-7295122-3128747?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/81EaTyjtFqL._AC_UL300_SR300,200_.jpg",
            "rating": 4.7,
            "ratings_total": 30251,
            "price": {
                "symbol": "$",
                "value": 99.99,
                "currency": "USD",
                "raw": "$99.99"
            }
        },
        {
            "asin": "B07SCGY2H6",
            "title": "Ninja SP101 Digital Air Fry Countertop Oven with 8-in-1 Functionality, Flip Up & Away Capability for Storage Space, with Air ",
            "rank": 11,
            "url": "https://www.amazon.com/Ninja-Multi-Purpose-Counter-top-Convection-SP101/dp/B07SCGY2H6/ref=zg_bs_g_17659096011_d_sccl_11/147-7295122-3128747?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/81iQGSa1M4L._AC_UL300_SR300,200_.jpg",
            "rating": 4.6,
            "ratings_total": 24460,
            "price": {
                "symbol": "$",
                "value": 147.99,
                "currency": "USD",
                "raw": "$147.99"
            }
        },
        {
            "asin": "B08R6KMBQT",
            "title": "Instant Vortex Plus 4QT Air Fryer, Custom Program Options, 6-in-1 Functions Crisps, Broils, Roasts, Dehydrates, Bakes, Reheat",
            "rank": 12,
            "url": "https://www.amazon.com/Instant-Touchscreen-Customizable-Programs-Stainless/dp/B08R6KMBQT/ref=zg_bs_g_17659096011_d_sccl_12/147-7295122-3128747?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/81yIRW8QyjL._AC_UL300_SR300,200_.jpg",
            "rating": 4.7,
            "ratings_total": 11149,
            "price": {
                "symbol": "$",
                "value": 69.99,
                "currency": "USD",
                "raw": "$69.99"
            }
        },
        {
            "asin": "B09HSWBTN4",
            "title": "Cuisinart Air Fryer + Convection Toaster Oven, 8-1 Oven with Bake, Grill, Broil & Warm Options, Stainless Steel, TOA-70",
            "rank": 13,
            "url": "https://www.amazon.com/Cuisinart-TOA-70-AirFryer-Oven-Grill/dp/B09HSWBTN4/ref=zg_bs_g_17659096011_d_sccl_13/147-7295122-3128747?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/71UIRKGLtYL._AC_UL300_SR300,200_.jpg",
            "rating": 4.5,
            "ratings_total": 3321,
            "price": {
                "symbol": "$",
                "value": 209.95,
                "currency": "USD",
                "raw": "$209.95"
            }
        },
        {
            "asin": "B0CDY277D3",
            "title": "CHEFMAN 6 Quart Dual Basket Air Fryer Oven with Easy View Windows, Sync Finish, Hi-Fry, Auto Shutoff, 2 Independent 3Qt Nonst",
            "rank": 14,
            "url": "https://www.amazon.com/CHEFMAN-Windows-Independent-Nonstick-Dishwasher/dp/B0CDY277D3/ref=zg_bs_g_17659096011_d_sccl_14/147-7295122-3128747?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/71OioGmCZmL._AC_UL300_SR300,200_.jpg",
            "rating": 4.3,
            "ratings_total": 912,
            "price": {
                "symbol": "$",
                "value": 99.99,
                "currency": "USD",
                "raw": "$99.99"
            }
        },
        {
            "asin": "B0CNY1F31S",
            "title": "Chefman Air Fryer – 4 QT Compact Airfryer for Quick & Easy Meals in Minutes, Features Hi-Fry Technology for Extra Crisp, Touc",
            "rank": 15,
            "url": "https://www.amazon.com/Chefman-Air-Fryer-Technology-Touchscreen/dp/B0CNY1F31S/ref=zg_bs_g_17659096011_d_sccl_15/147-7295122-3128747?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/71Ld+1SBc5L._AC_UL300_SR300,200_.jpg",
            "rating": 4.6,
            "ratings_total": 149,
            "price": {
                "symbol": "$",
                "value": 59.99,
                "currency": "USD",
                "raw": "$59.99"
            }
        },
        {
            "asin": "B0D7M7677Z",
            "title": "Air Fryer Oven 5.5 Qt, 6-in-1 Digital Display Compact Cooker, Space-saving, Nonstick & Dishwasher Safe Basket, Stainless Stee",
            "rank": 16,
            "url": "https://www.amazon.com/Space-saving-Dishwasher-Stainless-Kitchen-Elite/dp/B0D7M7677Z/ref=zg_bs_g_17659096011_d_sccl_16/147-7295122-3128747?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/61h8sVT3RfL._AC_UL300_SR300,200_.jpg",
            "rating": 4.3,
            "ratings_total": 126,
            "price": {
                "symbol": "$",
                "value": 53.99,
                "currency": "USD",
                "raw": "$53.99"
            }
        },
        {
            "asin": "B07VHFMZHJ",
            "title": "Instant Vortex Plus 6QT XL Air Fryer, 6-in-1, Broils, Dehydrates, Crisps, Roasts, Reheats, Bakes for Quick Easy Meals, 100+ I",
            "rank": 17,
            "url": "https://www.amazon.com/Instant-Vortex-Plus-Air-Fryer/dp/B07VHFMZHJ/ref=zg_bs_g_17659096011_d_sccl_17/147-7295122-3128747?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/71cn-i+GCZL._AC_UL300_SR300,200_.jpg",
            "rating": 4.6,
            "ratings_total": 34851,
            "price": {
                "symbol": "$",
                "value": 119.95,
                "currency": "USD",
                "raw": "$119.95"
            }
        },
        {
            "asin": "B0815KPZVB",
            "title": "DASH Tasti-Crisp™ Electric Air Fryer Oven, 2.6 Qt., Aqua – Compact Air Fryer for Healthier Food in Minutes, Ideal for Small S",
            "rank": 18,
            "url": "https://www.amazon.com/DASH-Tasti-CrispTM-Express-Fryer-Oven/dp/B0815KPZVB/ref=zg_bs_g_17659096011_d_sccl_18/147-7295122-3128747?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/514YCMftobL._AC_UL300_SR300,200_.jpg",
            "rating": 4.5,
            "ratings_total": 33244,
            "price": {
                "symbol": "$",
                "value": 41.99,
                "currency": "USD",
                "raw": "$41.99"
            }
        },
        {
            "asin": "B07VM28XTR",
            "title": "Instant Pot 10QT Air Fryer, 7-in-1 Functions with EvenCrisp Technology that Crisps, Broils, Bakes, Roasts, Dehydrates, Reheat",
            "rank": 19,
            "url": "https://www.amazon.com/Instant-Vortex-Plus-Rotisserie-10/dp/B07VM28XTR/ref=zg_bs_g_17659096011_d_sccl_19/147-7295122-3128747?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/810hkJ0gU2L._AC_UL300_SR300,200_.jpg",
            "rating": 4.4,
            "ratings_total": 38007,
            "price": {
                "symbol": "$",
                "value": 149.99,
                "currency": "USD",
                "raw": "$149.99"
            }
        },
        {
            "asin": "B08R12QZS1",
            "title": "Ninja, Toaster Oven, Air Fryer, Thermometer, Toaster Oven Air Fryer Combo, Stainless Steel, Quick meals, Fries, Frozen Food, ",
            "rank": 20,
            "url": "https://www.amazon.com/Ninja-Countertop-Dehydrate-Thermometer-1800-watts/dp/B08R12QZS1/ref=zg_bs_g_17659096011_d_sccl_20/147-7295122-3128747?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/81xMAsQJBsL._AC_UL300_SR300,200_.jpg",
            "rating": 4.6,
            "ratings_total": 6140,
            "price": {
                "symbol": "$",
                "value": 283,
                "currency": "USD",
                "raw": "$283.00"
            }
        },
        {
            "asin": "B096N28SZP",
            "title": "Instant Vortex Plus 6QT Air Fryer with Odor Erase Technology, 6-in-1 Functions that Crisps, Roasts, Broils, Dehydrates, Bakes",
            "rank": 21,
            "url": "https://www.amazon.com/Instant-Technology-Functions-Dehydrates-Reheats/dp/B096N28SZP/ref=zg_bs_g_17659096011_d_sccl_21/147-7295122-3128747?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/71+28qprSOL._AC_UL300_SR300,200_.jpg",
            "rating": 4.6,
            "ratings_total": 3943,
            "price": {
                "symbol": "$",
                "value": 129.95,
                "currency": "USD",
                "raw": "$129.95"
            }
        },
        {
            "asin": "B08QR5G3LT",
            "title": "DASH Tasti-Crisp™ Electric Air Fryer Oven, 2.6 Qt., Red – Compact Air Fryer for Healthier Food in Minutes, Ideal for Small Sp",
            "rank": 22,
            "url": "https://www.amazon.com/Tasti-CrispTM-Digital-AirCrisp%C2%AE-Technology-Temperature/dp/B08QR5G3LT/ref=zg_bs_g_17659096011_d_sccl_22/147-7295122-3128747?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/61Oh-UwvHSL._AC_UL300_SR300,200_.jpg",
            "rating": 4.5,
            "ratings_total": 6338,
            "price": {
                "symbol": "$",
                "value": 52.49,
                "currency": "USD",
                "raw": "$52.49"
            }
        },
        {
            "asin": "B0D96PPW3Z",
            "title": "T-fal Infrared Air fryer, Premium Infra-heat Technology, 7 in 1, Extra Crispy, Fast Heat-up, 6.3 Quart, 1550W, Digital Touch ",
            "rank": 23,
            "url": "https://www.amazon.com/Infrared-Premium-Infra-heat-Technology-Heat-up/dp/B0D96PPW3Z/ref=zg_bs_g_17659096011_d_sccl_23/147-7295122-3128747?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/51CJo+GEi1L._AC_UL300_SR300,200_.jpg",
            "rating": 4.7,
            "ratings_total": 48,
            "price": {
                "symbol": "$",
                "value": 129.99,
                "currency": "USD",
                "raw": "$129.99"
            }
        },
        {
            "asin": "B0BYF8PT3L",
            "title": "Cuisinart Air Fryer Oven – 6-Qt Basket Stainless Steel Air Fryer – Dishwasher-Safe Parts with 5 Presets – Roast, Bake, Broil,",
            "rank": 24,
            "url": "https://www.amazon.com/Cuisinart-Air-Fryer-Oven-Dishwasher-Safe/dp/B0BYF8PT3L/ref=zg_bs_g_17659096011_d_sccl_24/147-7295122-3128747?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/71zBH0kdZxL._AC_UL300_SR300,200_.jpg",
            "rating": 4.7,
            "ratings_total": 1105,
            "price": {
                "symbol": "$",
                "value": 149.95,
                "currency": "USD",
                "raw": "$149.95"
            }
        },
        {
            "asin": "B0CZS6SS3Y",
            "title": "Ninja SL401 DoubleStack XL 2-Basket Air Fryer, DoubleStack Technology Cooks 4 Foods at Once, Compact Design, 10 QT, 6-in-1, S",
            "rank": 25,
            "url": "https://www.amazon.com/Ninja-SL401-DoubleStack-2-Basket-Technology/dp/B0CZS6SS3Y/ref=zg_bs_g_17659096011_d_sccl_25/147-7295122-3128747?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/711xrX66pzL._AC_UL300_SR300,200_.jpg",
            "rating": 4.6,
            "ratings_total": 266,
            "price": {
                "symbol": "$",
                "value": 229.95,
                "currency": "USD",
                "raw": "$229.95"
            }
        },
        {
            "asin": "B0CLTK2JF8",
            "title": "Our Place Wonder Oven | 6-in-1 Air Fryer & Toaster Oven with Steam Infusion | Compact, Countertop Friendly, Fast Preheat, Mul",
            "rank": 26,
            "url": "https://www.amazon.com/Our-Place-Infusion-Countertop-Multifunctional/dp/B0CLTK2JF8/ref=zg_bs_g_17659096011_d_sccl_26/147-7295122-3128747?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/51KeshcLNYL._AC_UL300_SR300,200_.jpg",
            "rating": 4.3,
            "ratings_total": 317,
            "price": {
                "symbol": "$",
                "value": 195,
                "currency": "USD",
                "raw": "$195.00"
            }
        },
        {
            "asin": "B09MR49K1D",
            "title": "Gourmia Air Fryer Oven Digital Display 6 Quart Large AirFryer Cooker 12 1-Touch Cooking Presets, XL Air Fryer Basket 1500w Po",
            "rank": 27,
            "url": "https://www.amazon.com/Gourmia-GAF686-Digital-Guided-Cooking/dp/B09MR49K1D/ref=zg_bs_g_17659096011_d_sccl_27/147-7295122-3128747?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/91ayYfAfdiL._AC_UL300_SR300,200_.jpg",
            "rating": 4.6,
            "ratings_total": 2343,
            "price": {
                "symbol": "$",
                "value": 118,
                "currency": "USD",
                "raw": "$118.00"
            }
        },
        {
            "asin": "B0CVNHQ5X7",
            "title": "CHEFMAN 2 Qt Mini Air Fryer – Digital Space-Saving Compact Air Fryer with Nonstick and Dishwasher Safe Basket, Quick & Easy M",
            "rank": 28,
            "url": "https://www.amazon.com/CHEFMAN-Mini-Air-Fryer-Space-Saving/dp/B0CVNHQ5X7/ref=zg_bs_g_17659096011_d_sccl_28/147-7295122-3128747?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/61ieJQ4L4gL._AC_UL300_SR300,200_.jpg",
            "rating": 4.6,
            "ratings_total": 24258,
            "price": {
                "symbol": "$",
                "value": 54.25,
                "currency": "USD",
                "raw": "$54.25"
            }
        },
        {
            "asin": "B0CP8YG4GY",
            "title": "Air Fryer, Beelicious 8-in-1 Smart Compact 4QT Air Fryers, with Viewing Window,Shake Reminder,450°F Digital Airfryer with Fla",
            "rank": 29,
            "url": "https://www.amazon.com/Beelicious%C2%AE-Reminder-Airfryer-Flavor-Lock-Dishwasher-Safe/dp/B0CP8YG4GY/ref=zg_bs_g_17659096011_d_sccl_29/147-7295122-3128747?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/713nYvWqkRL._AC_UL300_SR300,200_.jpg",
            "rating": 4.6,
            "ratings_total": 483,
            "price": {
                "symbol": "$",
                "value": 74.99,
                "currency": "USD",
                "raw": "$74.99"
            }
        },
        {
            "asin": "B096N3FTZP",
            "title": "Instant Vortex Plus 6QT ClearCook Air Fryer, Clear Windows, Custom Program Options, 6-in-1 Functions, Crisps, Broils, Roasts,",
            "rank": 30,
            "url": "https://www.amazon.com/Instant-Vortex-ClearCook-Reheat-Dehydrate/dp/B096N3FTZP/ref=zg_bs_g_17659096011_d_sccl_30/147-7295122-3128747?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/71u0FNCrKfL._AC_UL300_SR300,200_.jpg",
            "rating": 4.7,
            "ratings_total": 6915,
            "price": {
                "symbol": "$",
                "value": 99.99,
                "currency": "USD",
                "raw": "$99.99"
            }
        },
        {
            "asin": "B0CCS2TH8Q",
            "title": "COSORI 9Qt 10-in-1 Dual Air Fryer, Fresh Balanced Meals for Family and Children with Double Baskets, Sync Cook & Finish to Ba",
            "rank": 31,
            "url": "https://www.amazon.com/COSORI-Multi-dish-Cooking-Baskets-Dishwasher/dp/B0CCS2TH8Q/ref=_d_sccl_31/131-4147728-3121444?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/81zJWuKQG3L._AC_UL300_SR300,200_.jpg",
            "rating": 4.8,
            "ratings_total": 1237,
            "price": {
                "symbol": "$",
                "value": 169.99,
                "currency": "USD",
                "raw": "$169.99"
            }
        },
        {
            "asin": "B0CJ3DGW13",
            "title": "Paris Hilton Air Fryer, Large 6-Quart Capacity, Touchscreen Display, 8-in-1 (Air Fry, Roast, Broil, Bake, Reheat, Keep Warm, ",
            "rank": 32,
            "url": "https://www.amazon.com/Paris-Hilton-Touchscreen-Dehydrate-Dishwasher/dp/B0CJ3DGW13/ref=_d_sccl_32/131-4147728-3121444?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/61Dpov9bO2L._AC_UL300_SR300,200_.jpg",
            "rating": 4.6,
            "ratings_total": 288,
            "price": {
                "symbol": "$",
                "value": 79.99,
                "currency": "USD",
                "raw": "$79.99"
            }
        },
        {
            "asin": "B0BY8XKZ85",
            "title": "Air Fryer Silicone Liners for Ninja Air Fryer AF101 4QT AF161/AF150 5.5QT, for COSORI/Gourmia/PowerXL/InstantPot/Fabuletta/Ph",
            "rank": 33,
            "url": "https://www.amazon.com/Silicone-Gourmia-InstantPot-Fabuletta-Airfryers/dp/B0BY8XKZ85/ref=_d_sccl_33/131-4147728-3121444?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/61w8xNoIRJL._AC_UL300_SR300,200_.jpg",
            "rating": 4.5,
            "ratings_total": 1013,
            "price": {
                "symbol": "$",
                "value": 9.99,
                "currency": "USD",
                "raw": "$9.99"
            }
        },
        {
            "asin": "B0CDBDTN9F",
            "title": "VAL CUCINA Infrared Heating Air Fryer Toaster Oven, Extra Large Countertop Convection Oven 10-in-1 Combo, 6-Slice Toast, Enam",
            "rank": 34,
            "url": "https://www.amazon.com/VAL-CUCINA-Infrared-Countertop-Convection/dp/B0CDBDTN9F/ref=_d_sccl_34/131-4147728-3121444?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/61ZzVcFTTqL._AC_UL300_SR300,200_.jpg",
            "rating": 4.5,
            "ratings_total": 1193,
            "price": {
                "symbol": "$",
                "value": 229.99,
                "currency": "USD",
                "raw": "$229.99"
            }
        },
        {
            "asin": "B08YS2ZZ97",
            "title": "Instant Vortex 2QT Mini Air Fryer, Small Air fryer that Crisps, Reheats, Bakes, Roasts for Quick Easy Meals, Includes over 10",
            "rank": 35,
            "url": "https://www.amazon.com/Instant-Customizable-Programs-Nonstick-Dishwasher-Safe/dp/B08YS2ZZ97/ref=_d_sccl_35/131-4147728-3121444?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/71RZGpkAodL._AC_UL300_SR300,200_.jpg",
            "rating": 4.7,
            "ratings_total": 15394,
            "price": {
                "symbol": "$",
                "value": 53.73,
                "currency": "USD",
                "raw": "$53.73"
            }
        },
        {
            "asin": "B09BZVWXJ5",
            "title": "Chefman Digital Air Fryer, Large 5 Qt Family Size, One Touch Digital Control Presets, French Fries, Chicken, Meat, Fish, Nons",
            "rank": 36,
            "url": "https://www.amazon.com/Chefman-Digital-Air-Fryer-Temperature/dp/B09BZVWXJ5/ref=_d_sccl_36/131-4147728-3121444?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/7153Kitf4tL._AC_UL300_SR300,200_.jpg",
            "rating": 4.6,
            "ratings_total": 1305,
            "price": {
                "symbol": "$",
                "value": 69.99,
                "currency": "USD",
                "raw": "$69.99"
            }
        },
        {
            "asin": "B09HKM5SVD",
            "title": "TOSHIBA Inverter Countertop Microwave Oven Air Fryer Combo MASTER Series, Broil, Convection, Speedy Combi, Even Defrost 11.3'",
            "rank": 37,
            "url": "https://www.amazon.com/Microwave-Technology-Convection-Defroster-Countertop/dp/B09HKM5SVD/ref=_d_sccl_37/131-4147728-3121444?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/71PL8HzUrZL._AC_UL300_SR300,200_.jpg",
            "rating": 4.1,
            "ratings_total": 1504,
            "price": {
                "symbol": "$",
                "value": 239.99,
                "currency": "USD",
                "raw": "$239.99"
            }
        },
        {
            "asin": "B0B7C327LK",
            "title": "Chefman 6 Quart Dual Basket Air Fryer - Digital Touchscreen, Smart Sync Finish, Hi-Fry, Auto Shutoff, 2 Independent 3QT Nonst",
            "rank": 38,
            "url": "https://www.amazon.com/Chefman-Quart-Dual-Basket-Fryer/dp/B0B7C327LK/ref=_d_sccl_38/131-4147728-3121444?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/81JxFs7KzPL._AC_UL300_SR300,200_.jpg",
            "rating": 4.4,
            "ratings_total": 1716,
            "price": {
                "symbol": "$",
                "value": 89.97,
                "currency": "USD",
                "raw": "$89.97"
            }
        },
        {
            "asin": "B0BBZZG4Z8",
            "title": "COSORI 11-in-1 Air Fryer Oven , 13Qt Small Footprint Airfryer combo with Rotisserie, Toast, Bake, Roast, Reheat Functions and",
            "rank": 39,
            "url": "https://www.amazon.com/COSORI-Functions-Rotisserie-Convection-Accessories/dp/B0BBZZG4Z8/ref=_d_sccl_39/131-4147728-3121444?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/91qsaX0anxL._AC_UL300_SR300,200_.jpg",
            "rating": 4.5,
            "ratings_total": 1158,
            "price": {
                "symbol": "$",
                "value": 109.99,
                "currency": "USD",
                "raw": "$109.99"
            }
        },
        {
            "asin": "B08PL327W4",
            "title": "Instant Omni Plus 19QT/18L Toaster Oven Air Fryer, 10-in-1 Functions, Fits 12\" Pizza, Crisp, Broil, Bake, Roast, Toast, Warm,",
            "rank": 40,
            "url": "https://www.amazon.com/Instant-Rotisserie-Convection-Dehydrator-Stainless/dp/B08PL327W4/ref=_d_sccl_40/131-4147728-3121444?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/81Xpg17Td5L._AC_UL300_SR300,200_.jpg",
            "rating": 4.6,
            "ratings_total": 11466,
            "price": {
                "symbol": "$",
                "value": 175.1,
                "currency": "USD",
                "raw": "$175.10"
            }
        },
        {
            "asin": "B0C1889LW9",
            "title": "CRUX x Marshmello 3.0 QT Digital Air Fryer with TurboCrisp Technology, Touch Screen Temperature Control, Timer and Auto Shut-",
            "rank": 41,
            "url": "https://www.amazon.com/Marshmello-TurboCrisp-Technology-Temperature-Programmable/dp/B0C1889LW9/ref=_d_sccl_41/131-4147728-3121444?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/61vCIailySL._AC_UL300_SR300,200_.jpg",
            "rating": 4.5,
            "ratings_total": 951,
            "price": {
                "symbol": "$",
                "value": 49.99,
                "currency": "USD",
                "raw": "$49.99"
            }
        },
        {
            "asin": "B0BZ52FLKC",
            "title": "Emeril Lagasse Dual Zone 360 Air Fryer Oven Combo with French Door, 25 QT Extra Large Cook Two Foods in Different Ways, Up to",
            "rank": 42,
            "url": "https://www.amazon.com/Emeril-Everyday-Temperture-different-Stainless/dp/B0BZ52FLKC/ref=_d_sccl_42/131-4147728-3121444?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/712UlJkRQ1L._AC_UL300_SR300,200_.jpg",
            "rating": 4.3,
            "ratings_total": 1831,
            "price": {
                "symbol": "$",
                "value": 299.99,
                "currency": "USD",
                "raw": "$299.99"
            }
        },
        {
            "asin": "B07NLHMYH4",
            "title": "Emeril Lagasse Everyday 360 Stainless Steel Air Fryer, 360° Quick Cook Technology, XL capacity, 9-in-1, 12 Pre-Set Cooking Fu",
            "rank": 43,
            "url": "https://www.amazon.com/Emeril-Lagasse-Power-360-Convection/dp/B07NLHMYH4/ref=_d_sccl_43/131-4147728-3121444?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/61aazm1BzHL._AC_UL300_SR300,200_.jpg",
            "rating": 4.4,
            "ratings_total": 16040,
            "price": {
                "symbol": "$",
                "value": 159.99,
                "currency": "USD",
                "raw": "$159.99"
            }
        },
        {
            "asin": "B085W3GN5J",
            "title": "COSORI 12-in-1 Air Fryer 5.8QT, Toast, Bake, Nutrition Facts for 100+ In-App Recipes, AdaptIQ Tech Fast Cook, for Main & Side",
            "rank": 44,
            "url": "https://www.amazon.com/COSORI-Nutrition-Leftovers-Detachable-Dishwasher/dp/B085W3GN5J/ref=_d_sccl_44/131-4147728-3121444?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/71sjKoGdSEL._AC_UL300_SR300,200_.jpg",
            "rating": 4.6,
            "ratings_total": 18414,
            "price": {
                "symbol": "$",
                "value": 109.99,
                "currency": "USD",
                "raw": "$109.99"
            }
        },
        {
            "asin": "B0CJDGK6TV",
            "title": "ASZ 5 qt Air Fryer with Window- Digital Airfryer 5qt for 2-3 people - for Office Dorm Room 8 Presets Bake Toast Broil Reheat ",
            "rank": 45,
            "url": "https://www.amazon.com/ASZ-Compact-Window-Airfryer-Portable/dp/B0CJDGK6TV/ref=_d_sccl_45/131-4147728-3121444?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/51Vg1BUqGoL._AC_UL300_SR300,200_.jpg",
            "rating": 4.3,
            "ratings_total": 608,
            "price": {
                "symbol": "$",
                "value": 59.99,
                "currency": "USD",
                "raw": "$59.99"
            }
        },
        {
            "asin": "B0DCCY5JCP",
            "title": "Nuwave Bravo Pro Smart Air Fryer Toaster Oven Combo, Airfryer Convection Oven Countertop, 12-in-1 Functions with Quicker & Ev",
            "rank": 46,
            "url": "https://www.amazon.com/Nuwave-Bravo-Toaster-Oven-Black/dp/B0DCCY5JCP/ref=_d_sccl_46/131-4147728-3121444?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/71Px1m018dL._AC_UL300_SR300,200_.jpg",
            "rating": 4.7,
            "ratings_total": 53,
            "price": {
                "symbol": "$",
                "value": 143.99,
                "currency": "USD",
                "raw": "$143.99"
            }
        },
        {
            "asin": "B0D33YNJ2R",
            "title": "BLACK+DECKER Purify 4QT Air Fryer, Timer with Auto Shut-off and Shake Reminder, Digital LED Touchscreen and 9 Presets, 1300W ",
            "rank": 47,
            "url": "https://www.amazon.com/BLACK-DECKER-Shut-off-Touchscreen-Dishwasher/dp/B0D33YNJ2R/ref=_d_sccl_47/131-4147728-3121444?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/610S5Y5fkSL._AC_UL300_SR300,200_.jpg",
            "rating": 4.6,
            "ratings_total": 197,
            "price": {
                "symbol": "$",
                "value": 69.99,
                "currency": "USD",
                "raw": "$69.99"
            }
        },
        {
            "asin": "B0CGRYRBL7",
            "title": "West Bend Compact Air Fryer 5-Quart Capacity with Digital Controls and 10 Cooking Presets, Nonstick Frying Basket, 1500-Watts",
            "rank": 48,
            "url": "https://www.amazon.com/West-Bend-Capacity-Controls-1500-Watts/dp/B0CGRYRBL7/ref=_d_sccl_48/131-4147728-3121444?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/71w8XfCsIpL._AC_UL300_SR300,200_.jpg",
            "rating": 4.5,
            "ratings_total": 392,
            "price": {
                "symbol": "$",
                "value": 52.79,
                "currency": "USD",
                "raw": "$52.79"
            }
        },
        {
            "asin": "B007P6SAZ4",
            "title": "Big Boss 16Qt Large Air Fryer Oven – Large Halogen Oven Cooker with 50+ Air Fryers Recipe Book for Quick + Easy Meals for Ent",
            "rank": 49,
            "url": "https://www.amazon.com/Big-Boss-Oil-less-Fryer-Silver/dp/B007P6SAZ4/ref=_d_sccl_49/131-4147728-3121444?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/812Y46fc-WL._AC_UL300_SR300,200_.jpg",
            "rating": 4.3,
            "ratings_total": 7962,
            "price": {
                "symbol": "$",
                "value": 69.95,
                "currency": "USD",
                "raw": "$69.95"
            }
        },
        {
            "asin": "B09BJHB2BD",
            "title": "COSORI 6.8Qt Air Fryer, 12-in-1 Dual Blaze for 360° Crispy Meals, No Shaking & Preheating to Fast Cooking with Bake, Roast, B",
            "rank": 50,
            "url": "https://www.amazon.com/COSORI-Functions-Elements-Preheating-Dishwasher/dp/B09BJHB2BD/ref=_d_sccl_50/131-4147728-3121444?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/81wmZ+Svf8L._AC_UL300_SR300,200_.jpg",
            "rating": 4.6,
            "ratings_total": 3675,
            "price": {
                "symbol": "$",
                "value": 159.97,
                "currency": "USD",
                "raw": "$159.97"
            }
        },
        {
            "asin": "B0CZ3LTTKX",
            "title": "Beelicious® 5.8QT Large Air Fryer, 12-in-1 Digital Airfryer with Clear Window, 40+ Recipes, Flavor-Lock Tech, Shake Reminder,",
            "rank": 51,
            "url": "https://www.amazon.com/Beelicious%C2%AE-Airfryer-Flavor-Lock-Reminder-Dishwasher/dp/B0CZ3LTTKX/ref=zg_bs_g_17659096011_d_sccl_1/138-1718086-7746505?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/71CqREk30pL._AC_UL300_SR300,200_.jpg",
            "rating": 4.5,
            "ratings_total": 168,
            "price": {
                "symbol": "$",
                "value": 99.99,
                "currency": "USD",
                "raw": "$99.99"
            }
        },
        {
            "asin": "B0C9JPTTFJ",
            "title": "Belaco Air Fryer, 2.1Qt Digital Display, Rapid Air Circulation, Air Fryers, 60 Min Timer, Small Air Fryer, Adjustable Tempera",
            "rank": 52,
            "url": "https://www.amazon.com/Belaco-Circulation-Adjustable-Temperature-BEL-AF-02/dp/B0C9JPTTFJ/ref=zg_bs_g_17659096011_d_sccl_2/138-1718086-7746505?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/61KHOgLoOLL._AC_UL300_SR300,200_.jpg",
            "rating": 4.2,
            "ratings_total": 569,
            "price": {
                "symbol": "$",
                "value": 33.99,
                "currency": "USD",
                "raw": "$33.99"
            }
        },
        {
            "asin": "B09ZBLYV2T",
            "title": "Ninja Foodi 11 Quart 7-in-1 MegaZone XL FlexBasket Air Fryer, Basket Divider for Full Meals, Air Fry, Roast, Grey (Renewed)",
            "rank": 53,
            "url": "https://www.amazon.com/Ninja-DualZone-Fryer-Baskets-Finish/dp/B09ZBLYV2T/ref=zg_bs_g_17659096011_d_sccl_3/138-1718086-7746505?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/81Lho8MVWPL._AC_UL300_SR300,200_.jpg",
            "rating": 4.7,
            "ratings_total": 1511,
            "price": {
                "symbol": "$",
                "value": 129.95,
                "currency": "USD",
                "raw": "$129.95"
            }
        },
        {
            "asin": "B0CGPYTVG4",
            "title": "Air Fryer, Beelicious 8-in-1 Smart Compact 4QT Air Fryers, Shake Reminder, 450°F Digital Airfryer with Flavor-Lock Tech, Temp",
            "rank": 54,
            "url": "https://www.amazon.com/Beelicious%C2%AE-Reminder-Airfryer-Flavor-Lock-Dishwasher-Safe/dp/B0CGPYTVG4/ref=zg_bs_g_17659096011_d_sccl_4/138-1718086-7746505?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/61eMB96xm0L._AC_UL300_SR300,200_.jpg",
            "rating": 4.5,
            "ratings_total": 600,
            "price": {
                "symbol": "$",
                "value": 79.99,
                "currency": "USD",
                "raw": "$79.99"
            }
        },
        {
            "asin": "B0CZ898D5Z",
            "title": "Tastee Air Fryer, 8-in-1 Compact 5.5QT AirFryers, 450°F Digital Airfryer Dehydrator with Dual-sensor Smart Probe, 24 Presets ",
            "rank": 55,
            "url": "https://www.amazon.com/Tastee-AirFryers-Dehydrator-Dual-sensor-Dishwasher-safe/dp/B0CZ898D5Z/ref=zg_bs_g_17659096011_d_sccl_5/138-1718086-7746505?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/71Zw0YM7HhL._AC_UL300_SR300,200_.jpg",
            "rating": 4.5,
            "ratings_total": 107,
            "price": {
                "symbol": "$",
                "value": 149.99,
                "currency": "USD",
                "raw": "$149.99"
            }
        },
        {
            "asin": "B0C1LDRFSK",
            "title": "Nuwave Bravo Air Fryer Toaster Smart Oven, 12-in-1 Countertop Convection, 1800 Watts, 21-Qt Capacity, 50°-450°F Temp Controls",
            "rank": 56,
            "url": "https://www.amazon.com/Nuwave-Oven-Countertop-Convection-Technology/dp/B0C1LDRFSK/ref=zg_bs_g_17659096011_d_sccl_6/138-1718086-7746505?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/81ot-6San8L._AC_UL300_SR300,200_.jpg",
            "rating": 4.4,
            "ratings_total": 796,
            "price": {
                "symbol": "$",
                "value": 134.99,
                "currency": "USD",
                "raw": "$134.99"
            }
        },
        {
            "asin": "B0CFLN442B",
            "title": "RAMJOY Air Fryer 3.8 Quarts for 1-2 people, 8-in-1 Functions, Air Fry, Roast, Bake, Broil, Preheat, Shake, Digital Small Air ",
            "rank": 57,
            "url": "https://www.amazon.com/RAMJOY-Functions-Preheat-Nonstick-Dishwasher-Safe/dp/B0CFLN442B/ref=zg_bs_g_17659096011_d_sccl_7/138-1718086-7746505?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/61QEmA0EeZL._AC_UL300_SR300,200_.jpg",
            "rating": 4.3,
            "ratings_total": 399,
            "price": {
                "symbol": "$",
                "value": 55.99,
                "currency": "USD",
                "raw": "$55.99"
            }
        },
        {
            "asin": "B0CQRW3N7L",
            "title": "Home Warehouse Premium Carbon Steel Air Fryer Basket for Oven | Superior Craftsmanship | Sleek Design | Robust, Rust-Proof an",
            "rank": 58,
            "url": "https://www.amazon.com/Home-Warehouse-Craftsmanship-Rust-Proof-Dishwasher-Safe/dp/B0CQRW3N7L/ref=zg_bs_g_17659096011_d_sccl_8/138-1718086-7746505?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/81G+mGgId-L._AC_UL300_SR300,200_.jpg",
            "rating": 4.6,
            "ratings_total": 48,
            "price": {
                "symbol": "$",
                "value": 10.99,
                "currency": "USD",
                "raw": "$10.99"
            }
        },
        {
            "asin": "B0CR3NJH3B",
            "title": "32QT Extra Large Air Fryer, Toaster Oven Air Fryer Combo, 360°Hot Air Circulation for Healthier Food, 1800W Preset Dual Cook,",
            "rank": 59,
            "url": "https://www.amazon.com/Circulation-Healthier-Double-Rack-7-Accessory-Transparent/dp/B0CR3NJH3B/ref=zg_bs_g_17659096011_d_sccl_9/138-1718086-7746505?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/716KIGPc0WL._AC_UL300_SR300,200_.jpg",
            "rating": 4.5,
            "ratings_total": 326,
            "price": {
                "symbol": "$",
                "value": 199.99,
                "currency": "USD",
                "raw": "$199.99"
            }
        },
        {
            "asin": "B0CMXN9ZF7",
            "title": "Midea Dual Basket Air Fryer Oven 11 Quart 8 in 1 Functions, Clear Window, Smart Sync Finish, Works with Alexa, Wi-Fi Connecti",
            "rank": 60,
            "url": "https://www.amazon.com/Midea-Basket-Functions-Connectivity-Recipes/dp/B0CMXN9ZF7/ref=zg_bs_g_17659096011_d_sccl_10/138-1718086-7746505?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/71z3GqbbuLL._AC_UL300_SR300,200_.jpg",
            "rating": 4.5,
            "ratings_total": 281,
            "price": {
                "symbol": "$",
                "value": 179.99,
                "currency": "USD",
                "raw": "$179.99"
            }
        },
        {
            "asin": "B08SJ6L9MT",
            "title": "PHILIPS 3000 Series Air Fryer Essential Compact with Rapid Air Technology, 13-in-1 Cooking Functions to Fry, Bake, Grill, Roa",
            "rank": 61,
            "url": "https://www.amazon.com/Philips-Kitchen-Appliances-Technology-91/dp/B08SJ6L9MT/ref=zg_bs_g_17659096011_d_sccl_11/138-1718086-7746505?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/51dXTCqGdfL._AC_UL300_SR300,200_.jpg",
            "rating": 4.5,
            "ratings_total": 1903,
            "price": {
                "symbol": "$",
                "value": 127.99,
                "currency": "USD",
                "raw": "$127.99"
            }
        },
        {
            "asin": "B0CRH8654W",
            "title": "BYKITCHEN 9 Inch Square Silicone Air Fryer Liners for 6 to 8QT, Set of 2, Reusable Silicone Air Fryer Tray Insert, Air Fryer ",
            "rank": 62,
            "url": "https://www.amazon.com/BYKITCHEN-Silicone-Reusable-Accessories-Corsori/dp/B0CRH8654W/ref=zg_bs_g_17659096011_d_sccl_12/138-1718086-7746505?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/71eNTw3-3kL._AC_UL300_SR300,200_.jpg",
            "rating": 4.2,
            "ratings_total": 120,
            "price": {
                "symbol": "$",
                "value": 14.99,
                "currency": "USD",
                "raw": "$14.99"
            }
        },
        {
            "asin": "B09TD18SC5",
            "title": "Ninja SP201 Digital Air Fry Pro Countertop 8-in-1 Oven with Extended Height, XL Capacity, Flip Up & Away Capability for Stora",
            "rank": 63,
            "url": "https://www.amazon.com/Ninja-Countertop-Extended-Capacity-Capability/dp/B09TD18SC5/ref=zg_bs_g_17659096011_d_sccl_13/138-1718086-7746505?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/81eJZm1zPbL._AC_UL300_SR300,200_.jpg",
            "rating": 4.6,
            "ratings_total": 104,
            "price": {
                "symbol": "$",
                "value": 99.99,
                "currency": "USD",
                "raw": "$99.99"
            }
        },
        {
            "asin": "B0BWRXLT9X",
            "title": "Quart Touchscreen Air Fryer, White Icing by Drew Barrymore",
            "rank": 64,
            "url": "https://www.amazon.com/Quart-Touchscreen-Fryer-White-Barrymore/dp/B0BWRXLT9X/ref=zg_bs_g_17659096011_d_sccl_14/138-1718086-7746505?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/5174uLubX2L._AC_UL300_SR300,200_.jpg",
            "rating": 4.3,
            "ratings_total": 60,
            "price": {
                "symbol": "$",
                "value": 49.99,
                "currency": "USD",
                "raw": "$49.99"
            }
        },
        {
            "asin": "B075X3287P",
            "title": "Nuwave Brio 10-in-1 Air Fryer 7.25Qt with Patented Linear T Thermal Technology for Crisping, Roasting, Dehydrating, and Rehea",
            "rank": 65,
            "url": "https://www.amazon.com/Nuwave-Technology-Dehydrating-Reheating-Dishwasher/dp/B075X3287P/ref=zg_bs_g_17659096011_d_sccl_15/138-1718086-7746505?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/71IteRILcTL._AC_UL300_SR300,200_.jpg",
            "rating": 4.4,
            "ratings_total": 10791,
            "price": {
                "symbol": "$",
                "value": 89.99,
                "currency": "USD",
                "raw": "$89.99"
            }
        },
        {
            "asin": "B0DFK4PJPX",
            "title": "Nuwave Brio Plus Air Fryer 8 Qt, New & Improved, PFAS-FREE, 7 Digital One-Touch Cook Functions that AIR FRY, BAKE, ROAST, DEH",
            "rank": 66,
            "url": "https://www.amazon.com/Nuwave-Brio-Plus-Air-Fryer/dp/B0DFK4PJPX/ref=zg_bs_g_17659096011_d_sccl_16/138-1718086-7746505?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/61oE8GvvhWL._AC_UL300_SR300,200_.jpg",
            "rating": 4.8,
            "ratings_total": 86,
            "price": {
                "symbol": "$",
                "value": 116.99,
                "currency": "USD",
                "raw": "$116.99"
            }
        },
        {
            "asin": "B08FBLZD8R",
            "title": "Yedi Evolution Air Fryer, 6.8 Quart, Stainless Steel, Ceramic Cooking Basket, with Deluxe Accessory Kit and Recipe Book",
            "rank": 67,
            "url": "https://www.amazon.com/Evolution-Stainless-Ceramic-Cooking-Accessory/dp/B08FBLZD8R/ref=zg_bs_g_17659096011_d_sccl_17/138-1718086-7746505?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/71vmuvOUeSS._AC_UL300_SR300,200_.jpg",
            "rating": 4.5,
            "ratings_total": 1934,
            "price": {
                "symbol": "$",
                "value": 129.95,
                "currency": "USD",
                "raw": "$129.95"
            }
        },
        {
            "asin": "B085LT31HP",
            "title": "Nuwave Brio 15.5Qt Air Fryer Rotisserie Oven, X-Large Family Size, Powerful 1800W, 4 Rack Positions, 50°-425°F Temp Controls,",
            "rank": 68,
            "url": "https://www.amazon.com/Nuwave-Rotisserie-Integrated-Thermometer-Technology/dp/B085LT31HP/ref=zg_bs_g_17659096011_d_sccl_18/138-1718086-7746505?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/71TwFe3PGpL._AC_UL300_SR300,200_.jpg",
            "rating": 4.4,
            "ratings_total": 8310,
            "price": {
                "symbol": "$",
                "value": 157.69,
                "currency": "USD",
                "raw": "$157.69"
            }
        },
        {
            "asin": "B0D8QNH6HK",
            "title": "Philips 2000 Series Airfryer - 4.4 qt (4.2 l) Pan, 13 Cooking Functions, RapidAir Technology, Healthy Cooking with 90% Less F",
            "rank": 69,
            "url": "https://www.amazon.com/Philips-2000-Airfryer-Functions-Technology/dp/B0D8QNH6HK/ref=zg_bs_g_17659096011_d_sccl_19/138-1718086-7746505?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/51Lik6CJiYL._AC_UL300_SR300,200_.jpg",
            "rating": 4.6,
            "ratings_total": 72,
            "price": {
                "symbol": "$",
                "value": 79.99,
                "currency": "USD",
                "raw": "$79.99"
            }
        },
        {
            "asin": "B0BXFQJK8B",
            "title": "Proctor Silex Simply-Crisp Toaster Oven Air Fryer Combo with 4 Functions Including Convection, Bake & Broil, Fits 6 Slices or",
            "rank": 70,
            "url": "https://www.amazon.com/Proctor-Silex-Functions-Convection-31275/dp/B0BXFQJK8B/ref=zg_bs_g_17659096011_d_sccl_20/138-1718086-7746505?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/71CAvDSBZXL._AC_UL300_SR300,200_.jpg",
            "rating": 4.3,
            "ratings_total": 440,
            "price": {
                "symbol": "$",
                "value": 71.23,
                "currency": "USD",
                "raw": "$71.23"
            }
        },
        {
            "asin": "B0CYGJSNPX",
            "title": "Heat Resistant Mat for Air Fryer,Deep Fryer,Oven,Gray 15x18in Hot Pads for Kitchen Counter Heat Protector,Deep Fryer Coffee M",
            "rank": 71,
            "url": "https://www.amazon.com/Resistant-15x18in-Protector-Accessories-Countertop/dp/B0CYGJSNPX/ref=zg_bs_g_17659096011_d_sccl_21/138-1718086-7746505?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/81SmaxM4ZIL._AC_UL300_SR300,200_.jpg",
            "rating": 4.6,
            "ratings_total": 117,
            "price": {
                "symbol": "$",
                "value": 13.99,
                "currency": "USD",
                "raw": "$13.99"
            }
        },
        {
            "asin": "B0CVCDM9T5",
            "title": "Nuwave Brio Plus 8 Qt Air Fryer, PFAS Free, New & Improved, Digital Touch Screen, Cool White Display, 50°F~400°F in Precise 5",
            "rank": 72,
            "url": "https://www.amazon.com/Nuwave-Improved-50%C2%B0F-400%C2%B0F-Functions-Wattages/dp/B0CVCDM9T5/ref=zg_bs_g_17659096011_d_sccl_22/138-1718086-7746505?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/61PxTLp8IlL._AC_UL300_SR300,200_.jpg",
            "rating": 4.4,
            "ratings_total": 8454,
            "price": {
                "symbol": "$",
                "value": 116.99,
                "currency": "USD",
                "raw": "$116.99"
            }
        },
        {
            "asin": "B09GL56L4T",
            "title": "Cuisinart TOA-26 Compact Airfryer Toaster Oven, 1800-Watt Motor with 6-in-1 Functions and Wide Temperature Range, Air Fryer, ",
            "rank": 73,
            "url": "https://www.amazon.com/Cuisinart-TOA-26-Compact-AirFryer-Toaster/dp/B09GL56L4T/ref=zg_bs_g_17659096011_d_sccl_23/138-1718086-7746505?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/71r2Z5O5ClL._AC_UL300_SR300,200_.jpg",
            "rating": 4.2,
            "ratings_total": 1278,
            "price": {
                "symbol": "$",
                "value": 129.95,
                "currency": "USD",
                "raw": "$129.95"
            }
        },
        {
            "asin": "B0D3X8VL6K",
            "title": "Air Fryer 10 Quart, Large Air Fryer for Families, 6 Cooking Functions AirFryer, 400°F Temp Controls in 5° Increments, Ceramic",
            "rank": 74,
            "url": "https://www.amazon.com/Families-Functions-AirFryer-Increments-Dishwasher-Safe/dp/B0D3X8VL6K/ref=zg_bs_g_17659096011_d_sccl_24/138-1718086-7746505?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/715jkn7GK+L._AC_UL300_SR300,200_.jpg",
            "rating": 4.7,
            "ratings_total": 49,
            "price": {
                "symbol": "$",
                "value": 129.99,
                "currency": "USD",
                "raw": "$129.99"
            }
        },
        {
            "asin": "B0CNT7NHFS",
            "title": "Larinest 5.3Qt Air Fryer, Compact Airfryer with Large Capacity for Quick and Easy Meals, 8 Cooking Functions on Digital Touch",
            "rank": 75,
            "url": "https://www.amazon.com/Larinest-Airfryer-Nonstick-Dishwasher-Safe-AF501/dp/B0CNT7NHFS/ref=zg_bs_g_17659096011_d_sccl_25/138-1718086-7746505?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/71H6+Kz2JGL._AC_UL300_SR300,200_.jpg",
            "rating": 4.3,
            "ratings_total": 131,
            "price": {
                "symbol": "$",
                "value": 43.19,
                "currency": "USD",
                "raw": "$43.19"
            }
        },
        {
            "asin": "B08FMT6N24",
            "title": "PowerXL Air Fryer 7 QT Maxx Classic, Extra Hot Air Fry, Cook, Crisp, Broil, Roast, Bake, High Gloss Finish, Nonstick, Black",
            "rank": 76,
            "url": "https://www.amazon.com/PowerXL-Fryer7-Special-Extra-Finish/dp/B08FMT6N24/ref=zg_bs_g_17659096011_d_sccl_26/138-1718086-7746505?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/51E4awvsAhL._AC_UL300_SR300,200_.jpg",
            "rating": 4.6,
            "ratings_total": 5079,
            "price": {
                "symbol": "$",
                "value": 119.99,
                "currency": "USD",
                "raw": "$119.99"
            }
        },
        {
            "asin": "B0D1BGLW25",
            "title": "Midea Flexify French Door Toaster Oven Air Fryer Combo, 26.4 QT, Extra Large Air Fryer Countertop Oven 10-in-1 Combo, 25% Fas",
            "rank": 77,
            "url": "https://www.amazon.com/Midea-Flexify-Toaster-Countertop-Stainless/dp/B0D1BGLW25/ref=zg_bs_g_17659096011_d_sccl_27/138-1718086-7746505?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/717IPcNbbvL._AC_UL300_SR300,200_.jpg",
            "rating": 4.6,
            "ratings_total": 42,
            "price": {
                "symbol": "$",
                "value": 169.99,
                "currency": "USD",
                "raw": "$169.99"
            }
        },
        {
            "asin": "B09SBSF4B4",
            "title": "Nostalgia Personal Air Fryer 1-Quart, Compact Space Saving, Adjustable 30 Minute Timer and Temperature Up To 400℉, Non-Stick ",
            "rank": 78,
            "url": "https://www.amazon.com/Nostalgia-Adjustable-Temperature-Non-Stick-Dishwasher/dp/B09SBSF4B4/ref=zg_bs_g_17659096011_d_sccl_28/138-1718086-7746505?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/51ygSkuKp9L._AC_UL300_SR300,200_.jpg",
            "rating": 4.6,
            "ratings_total": 281,
            "price": {
                "symbol": "$",
                "value": 34.99,
                "currency": "USD",
                "raw": "$34.99"
            }
        },
        {
            "asin": "B09TZZS2GJ",
            "title": "LATURE 4.2 QT Air Fryer Oven Cooker with Temperature and Time Control Dishwasher Non-stick Basket 6 Cook Presets Stainless St",
            "rank": 79,
            "url": "https://www.amazon.com/LATURE-Temperature-Dishwasher-Non-stick-Black-Knob/dp/B09TZZS2GJ/ref=zg_bs_g_17659096011_d_sccl_29/138-1718086-7746505?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/61E1WZlS2VL._AC_UL300_SR300,200_.jpg",
            "rating": 4.3,
            "ratings_total": 925,
            "price": {
                "symbol": "$",
                "value": 44.99,
                "currency": "USD",
                "raw": "$44.99"
            }
        },
        {
            "asin": "B09HKPSRWW",
            "title": "TOSHIBA Countertop Microwave Oven Air Fryer Combo, Inverter, Convection, Broil, Speedy Combi, Even Defrost, Humidity Sensor, ",
            "rank": 80,
            "url": "https://www.amazon.com/Microwave-Technology-Convection-Defroster-Countertop/dp/B09HKPSRWW/ref=zg_bs_g_17659096011_d_sccl_30/138-1718086-7746505?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/81ObjihULmL._AC_UL300_SR300,200_.jpg",
            "rating": 4,
            "ratings_total": 1016,
            "price": {
                "symbol": "$",
                "value": 339.99,
                "currency": "USD",
                "raw": "$339.99"
            }
        },
        {
            "asin": "B08GNYQR7S",
            "title": "COSMO COS-23AFAKB 2.3 Quart Electric Small Air Fryer with Temperature Control, Timer, Auto Shut-Off, Non-Stick Frying Tray, 1",
            "rank": 81,
            "url": "https://www.amazon.com/Cosmo-COS-23AFAKB-Electric-Temperature-Non-Stick/dp/B08GNYQR7S/ref=_d_sccl_81/133-1174861-1981621?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/71wuCPA6teL._AC_UL300_SR300,200_.jpg",
            "rating": 4.4,
            "ratings_total": 1389,
            "price": {
                "symbol": "$",
                "value": 34.49,
                "currency": "USD",
                "raw": "$34.49"
            }
        },
        {
            "asin": "B09871GCN1",
            "title": "32QT Extra Large Air Fryer, 19-In-1 Air Fryer Toaster Oven Combo with Rotisserie and Dehydrator, Digital Convection Oven Coun",
            "rank": 82,
            "url": "https://www.amazon.com/Beelicious-Rotisserie-Dehydrator-Countertop-Accessories/dp/B09871GCN1/ref=_d_sccl_82/133-1174861-1981621?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/71h6kniJYvL._AC_UL300_SR300,200_.jpg",
            "rating": 4.3,
            "ratings_total": 1303,
            "price": {
                "symbol": "$",
                "value": 229.99,
                "currency": "USD",
                "raw": "$229.99"
            }
        },
        {
            "asin": "B0BYFTZT3H",
            "title": "Breville the Joule® Oven Air Fryer Pro, Smart Countertop Oven, Small Electric Oven, Air Fryer Toaster Oven Combo, BOV950BSS, ",
            "rank": 83,
            "url": "https://www.amazon.com/Breville-Joule-BOV950BSS-Brushed-Stainless/dp/B0BYFTZT3H/ref=_d_sccl_83/133-1174861-1981621?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/61NWUyaF5xL._AC_UL300_SR300,200_.jpg",
            "rating": 4.5,
            "ratings_total": 348,
            "price": {
                "symbol": "$",
                "value": 499.95,
                "currency": "USD",
                "raw": "$499.95"
            }
        },
        {
            "asin": "B0BDMYMB3H",
            "title": "Breville the Smart Oven® Air Fryer, Convection Countertop Oven, Air Fryer Toaster Oven Combo, BOV860DBL, Damson Blue",
            "rank": 84,
            "url": "https://www.amazon.com/Breville-Smart-Oven-Fryer-Damson/dp/B0BDMYMB3H/ref=_d_sccl_84/133-1174861-1981621?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/61aaimiV+CL._AC_UL300_SR300,200_.jpg",
            "rating": 4.6,
            "ratings_total": 2375,
            "price": {
                "symbol": "$",
                "value": 349.95,
                "currency": "USD",
                "raw": "$349.95"
            }
        },
        {
            "asin": "B07VM1FT8W",
            "title": "Instant Vortex 6QT XL Air Fryer, 4-in-1 Functions that Crisps, Roasts, Reheats, Bakes for Quick Easy Meals, 100+ In-App Recip",
            "rank": 85,
            "url": "https://www.amazon.com/Instant-Pot-Customizable-Touchscreen-Dishwasher-Safe/dp/B07VM1FT8W/ref=_d_sccl_85/133-1174861-1981621?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/710QQoLGvuL._AC_UL300_SR300,200_.jpg",
            "rating": 4.6,
            "ratings_total": 12126,
            "price": {
                "symbol": "$",
                "value": 109.16,
                "currency": "USD",
                "raw": "$109.16"
            }
        },
        {
            "asin": "B0777RJG6D",
            "title": "GoWISE USA 1700-Watt 5.8-QT 8-in-1 Digital Air Fryer with Recipe Book, Black",
            "rank": 86,
            "url": "https://www.amazon.com/GoWISE-USA-5-8-Quarts-Electric-Recipes/dp/B0777RJG6D/ref=_d_sccl_86/133-1174861-1981621?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/61TppnY3pgL._AC_UL300_SR300,200_.jpg",
            "rating": 4.6,
            "ratings_total": 34562,
            "price": {
                "symbol": "$",
                "value": 75.2,
                "currency": "USD",
                "raw": "$75.20"
            }
        },
        {
            "asin": "B0CKP6Y6KB",
            "title": "Typhur Dome Air Fryer, No.1 Cooking Speed Large Air Fryer with Superior Airflow, Self-cleaning Smart Digital Air Fryer with D",
            "rank": 87,
            "url": "https://www.amazon.com/Typhur-Superior-Self-cleaning-Dishwasher-Capacity/dp/B0CKP6Y6KB/ref=_d_sccl_87/133-1174861-1981621?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/71ts9CdTlaL._AC_UL300_SR300,200_.jpg",
            "rating": 4.7,
            "ratings_total": 212,
            "price": {
                "symbol": "$",
                "value": 398.99,
                "currency": "USD",
                "raw": "$398.99"
            }
        },
        {
            "asin": "B0C353MQCY",
            "title": "BELLA 2 qt Manual Air Fryer Oven and 5-in-1 Multicooker with Removable Nonstick and Dishwasher Safe Crisping Tray and Basket,",
            "rank": 88,
            "url": "https://www.amazon.com/BELLA-Multicooker-Removable-Nonstick-Dishwasher/dp/B0C353MQCY/ref=_d_sccl_88/133-1174861-1981621?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/811MfVStaBL._AC_UL300_SR300,200_.jpg",
            "rating": 4.5,
            "ratings_total": 3391,
            "price": {
                "symbol": "$",
                "value": 39.99,
                "currency": "USD",
                "raw": "$39.99"
            }
        },
        {
            "asin": "B0D31SL5DV",
            "title": "11-QT Large Air Fryer - 8 in 1 XL Airfryer with Basket Divider, For Air Fry, Roast, Broil, Bake, Reheat, Dehydrate, Keep Warm",
            "rank": 89,
            "url": "https://www.amazon.com/11-QT-Large-Air-Fryer-Dishwasher/dp/B0D31SL5DV/ref=_d_sccl_89/133-1174861-1981621?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/61El4zk3IuL._AC_UL300_SR300,200_.jpg",
            "rating": 4,
            "ratings_total": 70,
            "price": {
                "symbol": "$",
                "value": 139.99,
                "currency": "USD",
                "raw": "$139.99"
            }
        },
        {
            "asin": "B08FTJJVQS",
            "title": "Elite Gourmet EAF5317D Digital 5.3Qt Hot Air Fryer 7 Menu Functions 1350W Oil-Less Healthy Cooker, Timer & Temperature Contro",
            "rank": 90,
            "url": "https://www.amazon.com/Elite-Gourmet-EAF5317D-Functions-Temperature/dp/B08FTJJVQS/ref=_d_sccl_90/133-1174861-1981621?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/71jaJlml-LL._AC_UL300_SR300,200_.jpg",
            "rating": 4.5,
            "ratings_total": 2883,
            "price": {
                "symbol": "$",
                "value": 41.65,
                "currency": "USD",
                "raw": "$41.65"
            }
        },
        {
            "asin": "B0BL74HQ4P",
            "title": "Cathy Yoder's Pine & Pepper Magnetic Air Fryer Temperature Chart & Cheat Sheet Set | Quick Reference Guide with Cook Time, Te",
            "rank": 91,
            "url": "https://www.amazon.com/Pine-Pepper-Magnetic-Temperature-Reference/dp/B0BL74HQ4P/ref=_d_sccl_91/133-1174861-1981621?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/81hze3TkGKL._AC_UL300_SR300,200_.jpg",
            "rating": 4.7,
            "ratings_total": 367,
            "price": {
                "symbol": "$",
                "value": 12.95,
                "currency": "USD",
                "raw": "$12.95"
            }
        },
        {
            "asin": "B08NCHMH1L",
            "title": "Elite Gourmet EAF1121 Personal 1.1 Qt. Compact Space Saving Electric Hot Air Fryer Oil-Less Healthy Cooker, Timer & Temperatu",
            "rank": 92,
            "url": "https://www.amazon.com/Elite-Gourmet-EAF1121-Personal-Temperature/dp/B08NCHMH1L/ref=_d_sccl_92/133-1174861-1981621?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/71cViepAYRL._AC_UL300_SR300,200_.jpg",
            "rating": 4.4,
            "ratings_total": 3377,
            "price": {
                "symbol": "$",
                "value": 34.99,
                "currency": "USD",
                "raw": "$34.99"
            }
        },
        {
            "asin": "B0CM5YZYXP",
            "title": "SIUDANGKA 4 Pack Air Fryer Liners for Cuisinart Air Fryer Cuisinart Convection Toaster Oven and Emeril Lagasse 26 QT Extra La",
            "rank": 93,
            "url": "https://www.amazon.com/SIUDANGKA-Liners-Cuisinart-Convection-Toaster/dp/B0CM5YZYXP/ref=_d_sccl_93/133-1174861-1981621?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/71HOi7iDXhL._AC_UL300_SR300,200_.jpg",
            "rating": 4.7,
            "ratings_total": 80,
            "price": {
                "symbol": "$",
                "value": 9.99,
                "currency": "USD",
                "raw": "$9.99"
            }
        },
        {
            "asin": "B0CGJ13QSD",
            "title": "Geek Chef Air Fryer Oven 26 QT, Big Air Fryer, Toast Oven with Bake, Roast, Pizza, Convection Oven, Easy to Use & Clean, 6 Sl",
            "rank": 94,
            "url": "https://www.amazon.com/Geek-Chef-Convection-Countertop-Stainless/dp/B0CGJ13QSD/ref=_d_sccl_94/133-1174861-1981621?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/719lJEbPZJL._AC_UL300_SR300,200_.jpg",
            "rating": 4.5,
            "ratings_total": 269,
            "price": {
                "symbol": "$",
                "value": 169.99,
                "currency": "USD",
                "raw": "$169.99"
            }
        },
        {
            "asin": "B0CY58Q74X",
            "title": "Kitchen Elite Air Fryer Stainless Steel with View Window, XL 8.5QT Large Airfryer, 8-in-1 Digital Control, 400°F Temp Control",
            "rank": 95,
            "url": "https://www.amazon.com/Kitchen-Elite-Stainless-Dishwasher-Safe-Space-Saving/dp/B0CY58Q74X/ref=_d_sccl_95/133-1174861-1981621?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/71ie9xUbaLL._AC_UL300_SR300,200_.jpg",
            "rating": 4.3,
            "ratings_total": 97,
            "price": {
                "symbol": "$",
                "value": 80.99,
                "currency": "USD",
                "raw": "$80.99"
            }
        },
        {
            "asin": "B0C8WN349L",
            "title": "Ninja Combi All-in-One Multicooker, Oven, & Air Fryer, Complete Meals in 15 Mins, 14-in-1 Functions, Combi Cooker + Air Fry, ",
            "rank": 96,
            "url": "https://www.amazon.com/Ninja-SFP701-Multicooker-Functions-Accessories/dp/B0C8WN349L/ref=_d_sccl_96/133-1174861-1981621?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/81Rc-+bdezL._AC_UL300_SR300,200_.jpg",
            "rating": 4.4,
            "ratings_total": 773,
            "price": {
                "symbol": "$",
                "value": 228.98,
                "currency": "USD",
                "raw": "$228.98"
            }
        },
        {
            "asin": "B0CJ2XD7JH",
            "title": "Air Fryer 8 Qt Large Size With Clear Window, 8 Presets, 3 Special Features Turn Reminder, Preheat, Light, True One-touch Pane",
            "rank": 97,
            "url": "https://www.amazon.com/Features-Reminder-One-touch-Nonstick-Stainless/dp/B0CJ2XD7JH/ref=_d_sccl_97/133-1174861-1981621?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/61575DWe9qL._AC_UL300_SR300,200_.jpg",
            "rating": 4.6,
            "ratings_total": 120,
            "price": {
                "symbol": "$",
                "value": 87.72,
                "currency": "USD",
                "raw": "$87.72"
            }
        },
        {
            "asin": "B0C1FSLTY7",
            "title": "Oster Extra-Large French Door Air Fryer Countertop Oven, Stainless Steel, 60-Min Timer, 6 Cooking Functions, Versatile Access",
            "rank": 98,
            "url": "https://www.amazon.com/Oster-Extra-Large-Countertop-Stainless-Accessories/dp/B0C1FSLTY7/ref=_d_sccl_98/133-1174861-1981621?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/71s4Kh8QOaL._AC_UL300_SR300,200_.jpg",
            "rating": 4.2,
            "ratings_total": 682,
            "price": {
                "symbol": "$",
                "value": 179,
                "currency": "USD",
                "raw": "$179.00"
            }
        },
        {
            "asin": "B0CK56G38C",
            "title": "Nuwave Duet Air Fryer and Electric Pressure Cooker Combo with 2 Switchable Lids, 300 FoolProof One-Touch Presets, Crisp&Tende",
            "rank": 99,
            "url": "https://www.amazon.com/Nuwave-Fryer-Pressure-Cooker-Combo/dp/B0CK56G38C/ref=_d_sccl_99/133-1174861-1981621?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/71YiNFeJWKL._AC_UL300_SR300,200_.jpg",
            "rating": 4.5,
            "ratings_total": 112,
            "price": {
                "symbol": "$",
                "value": 149.59,
                "currency": "USD",
                "raw": "$149.59"
            }
        },
        {
            "asin": "B0DCFV87KT",
            "title": "Kitchen Elite Air Fryer Oven with View Window, 9.5QT XL Large Airfryer Stainless Steel, 400°F Temp, 1min/Time Control, 9 1-To",
            "rank": 100,
            "url": "https://www.amazon.com/Kitchen-Elite-Airfryer-Stainless-Automatic/dp/B0DCFV87KT/ref=_d_sccl_100/133-1174861-1981621?psc=1",
            "image_url": "https://images-na.ssl-images-amazon.com/images/I/61l4XniYx1L._AC_UL300_SR300,200_.jpg",
            "rating": 5,
            "ratings_total": 2,
            "price": {
                "symbol": "$",
                "value": 97.99,
                "currency": "USD",
                "raw": "$97.99"
            }
        }
    ],
    "bestseller_asins_by_1gram": [
        {
            "one_gram": "5",
            "asins": [
                "B0C353MQCY",
                "B09BZVWXJ5",
                "B0CVCDM9T5",
                "B0CJDGK6TV",
                "B0936FGLQS",
                "B0BYF8PT3L",
                "B0CSZ7WBYW",
                "B0CGRYRBL7",
                "B0D3X8VL6K"
            ]
        },
        {
            "one_gram": "Presets",
            "asins": [
                "B0CGMFGX87",
                "B09BZVWXJ5",
                "B09MR49K1D",
                "B0CJDGK6TV",
                "B0BYF8PT3L",
                "B08DKYBTPH",
                "B0CJ2XD7JH",
                "B0CGRYRBL7",
                "B09TZZS2GJ",
                "B0CZ898D5Z",
                "B0CK56G38C",
                "B0D33YNJ2R"
            ]
        },
        {
            "one_gram": "AirFryer",
            "asins": [
                "B0D3X8VL6K",
                "B09MR49K1D"
            ]
        },
        {
            "one_gram": "Oven",
            "asins": [
                "B08R12QZS1",
                "B0CDY277D3",
                "B09MR49K1D",
                "B0C8WN349L",
                "B0BDMYMB3H",
                "B0CQRW3N7L",
                "B0BZ52FLKC",
                "B09TD18SC5",
                "B0CMXN9ZF7",
                "B0BXFQJK8B",
                "B0CM5YZYXP",
                "B0BYFTZT3H",
                "B0BYF8PT3L",
                "B09HKM5SVD",
                "B0D1BGLW25",
                "B0CGJ13QSD",
                "B09HSWBTN4",
                "B09B7SB46R",
                "B0BBZZG4Z8",
                "B0CYGJSNPX",
                "B09GL56L4T",
                "B0CLTK2JF8",
                "B09HKPSRWW",
                "B0815KPZVB",
                "B0CR3NJH3B",
                "B0DCCY5JCP",
                "B09871GCN1",
                "B07SCGY2H6",
                "B007P6SAZ4",
                "B09TZZS2GJ",
                "B0CDBDTN9F",
                "B0C353MQCY",
                "B0DCFV87KT",
                "B0D7M7677Z",
                "B0C1LDRFSK",
                "B0CGMFGX87",
                "B085LT31HP",
                "B0C1FSLTY7",
                "B08QR5G3LT",
                "B08PL327W4"
            ]
        },
        {
            "one_gram": "Steel",
            "asins": [
                "B0DCFV87KT",
                "B08R12QZS1",
                "B0CY58Q74X",
                "B0BYF8PT3L",
                "B0CQRW3N7L",
                "B08FBLZD8R",
                "B09B7SB46R",
                "B0C1FSLTY7",
                "B09HSWBTN4",
                "B07NLHMYH4"
            ]
        },
        {
            "one_gram": "Quart",
            "asins": [
                "B0BWRXLT9X",
                "B0CMXN9ZF7",
                "B09SBSF4B4",
                "B09ZBLYV2T",
                "B0CGMFGX87",
                "B0CDY277D3",
                "B0D96PPW3Z",
                "B08GNYQR7S",
                "B09MR49K1D",
                "B08FBLZD8R",
                "B0B7C327LK",
                "B0CGRYRBL7",
                "B0CJ3DGW13",
                "B0D3X8VL6K"
            ]
        },
        {
            "one_gram": "Digital",
            "asins": [
                "B0D96PPW3Z",
                "B0CZ3LTTKX",
                "B0DFK4PJPX",
                "B09MR49K1D",
                "B0C9JPTTFJ",
                "B0B7C327LK",
                "B0C1889LW9",
                "B0777RJG6D",
                "B0CZ898D5Z",
                "B09TD18SC5",
                "B0D33YNJ2R",
                "B09BZVWXJ5",
                "B0CVCDM9T5",
                "B0CP8YG4GY",
                "B0CY58Q74X",
                "B0CGPYTVG4",
                "B08DKYBTPH",
                "B09871GCN1",
                "B0CFLN442B",
                "B07SCGY2H6",
                "B0CVNHQ5X7",
                "B0D7M7677Z",
                "B0CNT7NHFS",
                "B0CGMFGX87",
                "B0CJDGK6TV",
                "B08FTJJVQS",
                "B0CGRYRBL7",
                "B0CKP6Y6KB"
            ]
        },
        {
            "one_gram": "XL",
            "asins": [
                "B0DCFV87KT",
                "B07VM1FT8W",
                "B0D31SL5DV",
                "B0CY58Q74X",
                "B09ZBLYV2T",
                "B09MR49K1D",
                "B08DKYBTPH",
                "B07VHFMZHJ",
                "B0CZS6SS3Y",
                "B09TD18SC5",
                "B07NLHMYH4"
            ]
        },
        {
            "one_gram": "Stainless",
            "asins": [
                "B0DCFV87KT",
                "B0D7M7677Z",
                "B08R12QZS1",
                "B0CY58Q74X",
                "B07NLHMYH4",
                "B0BYF8PT3L",
                "B08FBLZD8R",
                "B09TZZS2GJ",
                "B0C1FSLTY7",
                "B09HSWBTN4",
                "B09B7SB46R"
            ]
        },
        {
            "one_gram": "Large",
            "asins": [
                "B0CZ3LTTKX",
                "B09MR49K1D",
                "B0BZ52FLKC",
                "B09BZVWXJ5",
                "B0C1FSLTY7",
                "B0D1BGLW25",
                "B09B7SB46R",
                "B0D31SL5DV",
                "B0CR3NJH3B",
                "B0CY58Q74X",
                "B0CJ2XD7JH",
                "B0C33CHG99",
                "B09871GCN1",
                "B007P6SAZ4",
                "B0CDBDTN9F",
                "B0D3X8VL6K",
                "B0DCFV87KT",
                "B0CNT7NHFS",
                "B085LT31HP",
                "B0CJ3DGW13",
                "B0CKP6Y6KB"
            ]
        },
        {
            "one_gram": "Cooker",
            "asins": [
                "B08NCHMH1L",
                "B0D7M7677Z",
                "B09MR49K1D",
                "B08FTJJVQS",
                "B0C8WN349L",
                "B007P6SAZ4",
                "B09TZZS2GJ",
                "B0CK56G38C"
            ]
        },
        {
            "one_gram": "Gourmia",
            "asins": [
                "B0BY8XKZ85",
                "B09MR49K1D"
            ]
        },
        {
            "one_gram": "Touch",
            "asins": [
                "B0CNT7NHFS",
                "B0D96PPW3Z",
                "B09BZVWXJ5",
                "B0DFK4PJPX",
                "B09MR49K1D",
                "B0CVCDM9T5",
                "B0936FGLQS",
                "B08DKYBTPH",
                "B0C1889LW9",
                "B0CK56G38C"
            ]
        },
        {
            "one_gram": "QT",
            "asins": [
                "B0CZ3LTTKX",
                "B07VHFMZHJ",
                "B0B7C327LK",
                "B0C1889LW9",
                "B0BZ52FLKC",
                "B0777RJG6D",
                "B0CZ898D5Z",
                "B0D33YNJ2R",
                "B0CM5YZYXP",
                "B0D1BGLW25",
                "B0CP8YG4GY",
                "B0CGJ13QSD",
                "B09B7SB46R",
                "B08YS2ZZ97",
                "B07VM1FT8W",
                "B0D31SL5DV",
                "B085W3GN5J",
                "B0CR3NJH3B",
                "B0CGPYTVG4",
                "B08FMT6N24",
                "B0CY58Q74X",
                "B09871GCN1",
                "B096N3FTZP",
                "B0CNY1F31S",
                "B09TZZS2GJ",
                "B0CZS6SS3Y",
                "B0CRH8654W",
                "B08R6KMBQT",
                "B0DCFV87KT",
                "B0BY8XKZ85",
                "B0DDDD8WD6",
                "B0CSZ7WBYW",
                "B096N28SZP",
                "B07VM28XTR",
                "B08PL327W4"
            ]
        },
        {
            "one_gram": "Display",
            "asins": [
                "B0D7M7677Z",
                "B09MR49K1D",
                "B0CVCDM9T5",
                "B0C9JPTTFJ",
                "B0CJ3DGW13"
            ]
        },
        {
            "one_gram": "Cooking",
            "asins": [
                "B0CNT7NHFS",
                "B0D8QNH6HK",
                "B09MR49K1D",
                "B0DDDD8WD6",
                "B08SJ6L9MT",
                "B09BJHB2BD",
                "B08FBLZD8R",
                "B0CGRYRBL7",
                "B0C1FSLTY7",
                "B0D3X8VL6K",
                "B07NLHMYH4",
                "B0CKP6Y6KB"
            ]
        },
        {
            "one_gram": "FRY",
            "asins": [
                "B0DFK4PJPX"
            ]
        },
        {
            "one_gram": "360",
            "asins": [
                "B09BJHB2BD",
                "B07NLHMYH4",
                "B0BZ52FLKC",
                "B0CR3NJH3B"
            ]
        },
        {
            "one_gram": "Basket",
            "asins": [
                "B0C353MQCY",
                "B0CMXN9ZF7",
                "B0D7M7677Z",
                "B0D31SL5DV",
                "B09ZBLYV2T",
                "B0CDY277D3",
                "B09MR49K1D",
                "B0BYF8PT3L",
                "B0CQRW3N7L",
                "B08FBLZD8R",
                "B0B7C327LK",
                "B0CGRYRBL7",
                "B09TZZS2GJ",
                "B0CZS6SS3Y",
                "B0CVNHQ5X7"
            ]
        },
        {
            "one_gram": "Air",
            "asins": [
                "B0D96PPW3Z",
                "B0CDY277D3",
                "B0CQRW3N7L",
                "B0C1889LW9",
                "B0BXFQJK8B",
                "B09BZVWXJ5",
                "B0BYFTZT3H",
                "B07FDJMC9Q",
                "B0CGJ13QSD",
                "B09GL56L4T",
                "B09HKPSRWW",
                "B0D31SL5DV",
                "B085W3GN5J",
                "B0CY58Q74X",
                "B0CJ2XD7JH",
                "B08DKYBTPH",
                "B0CDBDTN9F",
                "B0CVNHQ5X7",
                "B0CNT7NHFS",
                "B0C1LDRFSK",
                "B0BY8XKZ85",
                "B0CJDGK6TV",
                "B0936FGLQS",
                "B08FBLZD8R",
                "B0CJ3DGW13",
                "B0CKP6Y6KB",
                "B09SBSF4B4",
                "B0CZ3LTTKX",
                "B0DFK4PJPX",
                "B09MR49K1D",
                "B0C9JPTTFJ",
                "B07VHFMZHJ",
                "B0B7C327LK",
                "B0BZ52FLKC",
                "B09TD18SC5",
                "B0BL74HQ4P",
                "B0BWRXLT9X",
                "B0CMXN9ZF7",
                "B0CP8YG4GY",
                "B09B7SB46R",
                "B0815KPZVB",
                "B07VM1FT8W",
                "B08GNYQR7S",
                "B08FMT6N24",
                "B09BJHB2BD",
                "B09871GCN1",
                "B0CFLN442B",
                "B08R6KMBQT",
                "B0D7M7677Z",
                "B0DDDD8WD6",
                "B0CSZ7WBYW",
                "B0BDFRZX3F",
                "B0C8WN349L",
                "B0777RJG6D",
                "B08NCHMH1L",
                "B0CM5YZYXP",
                "B0BYF8PT3L",
                "B09HKM5SVD",
                "B09HSWBTN4",
                "B0BBZZG4Z8",
                "B0CLTK2JF8",
                "B0CR3NJH3B",
                "B0DCCY5JCP",
                "B0CGPYTVG4",
                "B08SJ6L9MT",
                "B07SCGY2H6",
                "B096N3FTZP",
                "B0CZS6SS3Y",
                "B0CRH8654W",
                "B07NLHMYH4",
                "B0C353MQCY",
                "B0DCFV87KT",
                "B09ZBLYV2T",
                "B0CL12QNNK",
                "B085LT31HP",
                "B0CGRYRBL7",
                "B0C1FSLTY7",
                "B08QR5G3LT",
                "B08R12QZS1",
                "B0BDMYMB3H",
                "B0CZ898D5Z",
                "B0CK56G38C",
                "B0D33YNJ2R",
                "B0CVCDM9T5",
                "B0D1BGLW25",
                "B075X3287P",
                "B0CYGJSNPX",
                "B08YS2ZZ97",
                "B0CCS2TH8Q",
                "B0C33CHG99",
                "B0CNY1F31S",
                "B007P6SAZ4",
                "B09TZZS2GJ",
                "B0D3X8VL6K",
                "B0CGMFGX87",
                "B08FTJJVQS",
                "B096N28SZP",
                "B07VM28XTR",
                "B08PL327W4"
            ]
        },
        {
            "one_gram": "12",
            "asins": [
                "B085W3GN5J",
                "B0C1LDRFSK",
                "B0CGMFGX87",
                "B0DCCY5JCP",
                "B0CZ3LTTKX",
                "B09MR49K1D",
                "B09BJHB2BD",
                "B08PL327W4",
                "B07NLHMYH4"
            ]
        }
    ],
    "bestseller_asins_by_2gram": [
        {
            "two_gram": "Touch Cooking",
            "asins": [
                "B09MR49K1D"
            ]
        },
        {
            "two_gram": "XL Air",
            "asins": [
                "B07VM1FT8W",
                "B07VHFMZHJ",
                "B09MR49K1D"
            ]
        },
        {
            "two_gram": "Presets XL",
            "asins": [
                "B09MR49K1D"
            ]
        },
        {
            "two_gram": "Gourmia Air",
            "asins": [
                "B09MR49K1D"
            ]
        },
        {
            "two_gram": "Oven Digital",
            "asins": [
                "B0CGMFGX87",
                "B09MR49K1D"
            ]
        },
        {
            "two_gram": "Cooker 12",
            "asins": [
                "B09MR49K1D"
            ]
        },
        {
            "two_gram": "Cooking Presets",
            "asins": [
                "B09MR49K1D",
                "B0CGRYRBL7"
            ]
        },
        {
            "two_gram": "Fryer Basket",
            "asins": [
                "B0CQRW3N7L",
                "B09MR49K1D",
                "B09ZBLYV2T"
            ]
        },
        {
            "two_gram": "1500 w",
            "asins": [
                "B09MR49K1D"
            ]
        },
        {
            "two_gram": "Quart Large",
            "asins": [
                "B0D3X8VL6K",
                "B09MR49K1D"
            ]
        },
        {
            "two_gram": "Stainless Steel",
            "asins": [
                "B0DCFV87KT",
                "B08R12QZS1",
                "B0CY58Q74X",
                "B0BYF8PT3L",
                "B08FBLZD8R",
                "B09B7SB46R",
                "B0C1FSLTY7",
                "B09HSWBTN4",
                "B07NLHMYH4"
            ]
        },
        {
            "two_gram": "Large AirFryer",
            "asins": [
                "B09MR49K1D"
            ]
        },
        {
            "two_gram": "Digital Display",
            "asins": [
                "B09MR49K1D",
                "B0C9JPTTFJ",
                "B0D7M7677Z"
            ]
        },
        {
            "two_gram": "Basket 1500",
            "asins": [
                "B09MR49K1D",
                "B0CGRYRBL7"
            ]
        },
        {
            "two_gram": "AirFryer Cooker",
            "asins": [
                "B09MR49K1D"
            ]
        },
        {
            "two_gram": "Air Fryer",
            "asins": [
                "B0CDY277D3",
                "B0CQRW3N7L",
                "B0C1889LW9",
                "B0BXFQJK8B",
                "B09BZVWXJ5",
                "B0BYFTZT3H",
                "B07FDJMC9Q",
                "B0CGJ13QSD",
                "B09GL56L4T",
                "B09HKPSRWW",
                "B0D31SL5DV",
                "B085W3GN5J",
                "B0CY58Q74X",
                "B0CJ2XD7JH",
                "B08DKYBTPH",
                "B0CDBDTN9F",
                "B0CVNHQ5X7",
                "B0CNT7NHFS",
                "B0C1LDRFSK",
                "B0BY8XKZ85",
                "B0CJDGK6TV",
                "B0936FGLQS",
                "B08FBLZD8R",
                "B0CJ3DGW13",
                "B0CKP6Y6KB",
                "B09SBSF4B4",
                "B0CZ3LTTKX",
                "B0DFK4PJPX",
                "B09MR49K1D",
                "B0C9JPTTFJ",
                "B07VHFMZHJ",
                "B0B7C327LK",
                "B0BZ52FLKC",
                "B0BL74HQ4P",
                "B0BWRXLT9X",
                "B0CMXN9ZF7",
                "B0CP8YG4GY",
                "B09B7SB46R",
                "B0815KPZVB",
                "B07VM1FT8W",
                "B08GNYQR7S",
                "B08FMT6N24",
                "B09BJHB2BD",
                "B09871GCN1",
                "B0CFLN442B",
                "B08R6KMBQT",
                "B0D7M7677Z",
                "B0DDDD8WD6",
                "B0CSZ7WBYW",
                "B0BDFRZX3F",
                "B0C8WN349L",
                "B0777RJG6D",
                "B08NCHMH1L",
                "B0CM5YZYXP",
                "B0BYF8PT3L",
                "B09HKM5SVD",
                "B09HSWBTN4",
                "B0BBZZG4Z8",
                "B0CLTK2JF8",
                "B0CR3NJH3B",
                "B0DCCY5JCP",
                "B0CGPYTVG4",
                "B08SJ6L9MT",
                "B096N3FTZP",
                "B0CZS6SS3Y",
                "B0CRH8654W",
                "B07NLHMYH4",
                "B0C353MQCY",
                "B0DCFV87KT",
                "B09ZBLYV2T",
                "B0CL12QNNK",
                "B085LT31HP",
                "B0CGRYRBL7",
                "B0C1FSLTY7",
                "B08QR5G3LT",
                "B08R12QZS1",
                "B0BDMYMB3H",
                "B0CZ898D5Z",
                "B0CK56G38C",
                "B0D33YNJ2R",
                "B0CVCDM9T5",
                "B0D1BGLW25",
                "B075X3287P",
                "B0CYGJSNPX",
                "B08YS2ZZ97",
                "B0CCS2TH8Q",
                "B0C33CHG99",
                "B0CNY1F31S",
                "B007P6SAZ4",
                "B09TZZS2GJ",
                "B0D3X8VL6K",
                "B0CGMFGX87",
                "B08FTJJVQS",
                "B096N28SZP",
                "B07VM28XTR",
                "B08PL327W4"
            ]
        },
        {
            "two_gram": "5 QT",
            "asins": [
                "B0CSZ7WBYW"
            ]
        },
        {
            "two_gram": "5 Quart",
            "asins": [
                "B0CGRYRBL7"
            ]
        },
        {
            "two_gram": "Fryer Oven",
            "asins": [
                "B0C353MQCY",
                "B0DCFV87KT",
                "B0CMXN9ZF7",
                "B0815KPZVB",
                "B0D7M7677Z",
                "B0CGMFGX87",
                "B0CDY277D3",
                "B09MR49K1D",
                "B0BYF8PT3L",
                "B0CYGJSNPX",
                "B0BZ52FLKC",
                "B007P6SAZ4",
                "B0CGJ13QSD",
                "B09TZZS2GJ",
                "B08QR5G3LT",
                "B0BBZZG4Z8"
            ]
        }
    ]
}